
export const openLink = (destination: string) => {
	if (destination) {
		const link = ensureHasProtocol(destination);
		console.log(link);
		window.open(link, '_blank');
	}
};


export function isURLValid(link: string) {
	var pattern = new RegExp('^(http|https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
	return !!pattern.test(link);
}

export const defaultLeftSidebar = {
	moveAround: { active: false, status: false },
	blur: { active: false, status: false },
	editHotspot: { active: false, status: false },
	addHotspot: { active: false, status: false },
	stillImage: { active: false, status: false },
	addImage: { active: false, status: false },
	annotation: { active: false, status: false },
	note: { active: false, status: false },
	editImage: { active: false, status: false },
	linksMap: { active: false, status: false },
	floorPlan: { active: false, status: false },
	share: { active: false, status: false },
	settings: { active: false, status: false },
	audio: { active: false, status: false },
	returnLink: { active: false, status: false },
	publish: { active: false, status: false },
}

export interface ILeftSidebar {
	[key: string]: {
		active: boolean;
		status: boolean;
	}
}


function ensureHasProtocol(url: string) {
	if (!url.startsWith("http://") && !url.startsWith("https://")) {
		return "https://" + url;
	}
	return url;
}

