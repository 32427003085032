import React, { useEffect, useRef, useState } from 'react';
import { Trans } from '@lingui/react';
import NavSidebar from '../../utils/nav-sidebar/nav-sidebar';
import { connect, connectToUserNoPassword, getUsers } from '../../../api-helper/api-user';
import Footer from '../../utils/footer/footer';
import { EnumProPlan, IAccount, IAccountMaster, typeProPlan } from '../../../api-helper/interface/interfaces';
import { ReportPageView, ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent, EnumPagesTitles } from '../../../google-analytics-track-list';
// import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import './account-management.scss';
import { Tabs, TabHooks, PanelHooks } from '../../utils/tabs/tabs';
import { getMasters, searchPartnerByKeyword } from '../../../api-helper/api-admin';
import UsersAccountManagement from './users-account-management';
/*import { accountManagementSteps, createAccountSteps , editAccountSteps  } from '../../utils/tooltips/tooltips-data';*/
import { onChangeSortBy, onChangeSortByAdmin } from './account-management-utils';
import AdminsAccountManagement from './admin-account-management';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import { IModules, IPagination } from '../dashboard/dashboard';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { useHistory } from 'react-router';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { Link } from 'react-router-dom';
import Pagination from '../../utils/pagination/pagination';

export interface IUserAccount {
  id: string | '';
  fullName: string;
  email: string;
  countryId: string;
  status: number;
  tourCount?: number;
  proExpiration?: Date;
  partnerName?: string;
  partnerId?: string;
  accounts?: number;
  createdAt: string;
  updatedAt: string;
}

export enum EnumAccountStatus {
  Pending = 0,
  Active = 1,
  Disable = 2,
  Deleted = -1,
}

export interface IChangeSortPagination {
  sortBy?: string;
  page?: number;
}

interface IProps {
  orderOptionName: string;
}

const AccountManagement: React.FC<IProps> = ({ orderOptionName }) => {
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();
  const { handleLogoutState } = useAuth();
  const accountManagementRef = useRef<any>(null);
  const [usersData, setUsersData] = useState<IAccount[]>();
  const [masterData, setMasterData] = useState<IAccountMaster[]>();
  const [userPage, setUserPage] = useState<number>(0);
  const [userPageCount, setUserPageCount] = useState<number>(0);
  const [masterPage, setMasterPage] = useState<number>(0);
  const [masterPageCount, setMasterPageCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalCountAdmin, setTotalCountAdmin] = useState<number>(0);
  const [totalNumAccounts, setTotalNumAccounts] = useState<number>(0);
  const [keyword, setKeyword] = useState<string>('');
  const [keywordAdmin, setKeywordAdmin] = useState<string>('');
  const [loadedView, setLoadedView] = useState<boolean>(false);
  const [loadedAdminView, setLoadedAdminView] = useState<boolean>(false);
  const [master, setMaster] = useState<boolean>(true);
  const [showCustomDropDown, setShowCustomDropDown] = useState<boolean>(false);
  const [showCustomDropDownAdmin, setShowCustomDropDownAdmin] = useState<boolean>(false);
  const [userOrderOptionName, setUserOrderOptionName] = useState(linguiTranslateToString('Newest to Oldest'));
  const [masterOrderOptionName, setMasterOrderOptionName] = useState(linguiTranslateToString('Newest to Oldest'));
  const [userOrderOption, setUserOrderOption] = useState(linguiTranslateToString('newest'));
  const [masterOrderOption, setMasterOrderOption] = useState(linguiTranslateToString('newest'));
  const [userFilterByMasterName, setUserFilterByMasterName] = useState<string>();
  const [userLoadedPage, setUserLoadedPage] = useState(false);
  const [masterLoadedPage, setMasterLoadedPage] = useState(false);
  const [isTooltipClicked, setIsTooltipClicked] = useState<boolean>(false);
  //   const [currentTooltipStep, setCurrentTooltipStep] = useState<number>(0);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showMasterTab, setShowMasterTab] = useState<boolean>(false);
  const [showTabs, setShowTabs] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>();
  const config = useConfig();
  const [adminsNames, setAdminsNames] = useState<any>();

  useEffect(() => {
    if (config) {
      const mastersTab = config.modules.find((a: IModules) => Object.keys(a)[0] === 'masterTab')?.masterTab;
      setShowMasterTab(mastersTab);
      setMaster(mastersTab);
      getAdmin();
      if (!mastersTab) {
        getUsersData();
        history.push('/users-management');
      }
      setShowTabs(true);
    }
  }, [config]);

  const getUsersData = async (previousValues?: IChangeSortPagination) => {
    try {
      const sort = previousValues?.sortBy !== undefined ? previousValues.sortBy : userOrderOption;
      const pagination = previousValues?.page !== undefined ? previousValues?.page : userPage;
      const responseData = await getUsers(pagination, keyword, sort, userFilterByMasterName);
      setUserPageCount(Math.ceil(responseData.headers['x-pagination-count']));
      setTotalCount(responseData.headers['x-total-count']);
      if (responseData?.data) {
        const tempResult = [...responseData.data];
        const tempUsers = tempResult.map(user => {
          const tempUser = { ...user };
          const test = (typeProPlan.find(a => a.id === tempUser.proPlan)?.value as EnumProPlan) || tempUser.proPlan;
          tempUser.proPlan = test;
          return tempUser;
        });
        setUsersData(tempUsers);
      }
      setUserLoadedPage(true);
      if (previousValues?.page !== undefined) {
        setUserPage(previousValues.page);
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setUserLoadedPage(true);
      if (previousValues?.page !== undefined) {
        setUserPage(previousValues?.page);
      }
    }
  };

  const getMasterData = async (previousValues?: IChangeSortPagination) => {
    try {
      const pagination = previousValues?.page !== undefined ? previousValues.page : masterPage;
      const sort = previousValues?.sortBy !== undefined ? previousValues.sortBy : masterOrderOption;
      const responseData = await getMasters(pagination, keywordAdmin, sort);
      setMasterPageCount(Math.ceil(responseData.headers['x-pagination-count']));
      setTotalCountAdmin(responseData.headers['x-total-count']);

      if (responseData?.data) {
        setMasterData(responseData.data);
      }
      setMasterLoadedPage(true);
      if (previousValues?.page !== undefined) {
        setMasterPage(previousValues.page);
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setMasterLoadedPage(true);
    }
  };

  const getAdmin = async () => {
    try {
      const responseData = await searchPartnerByKeyword('');
      setAdminsNames(responseData);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const handleConnectUser = async (account: IUserAccount) => {
    ReportCustomEvent(EnumCategory.AccountMenu, EnumEvent.AccessAccount);
    try {
      const userConnect = await connectToUserNoPassword(account.id || account.partnerId || '');
      if (!userConnect.data.token) {
        throw new Error('connection failed');
      }
      await connect(userConnect.data.token, 'accountManagement');
      document.location.href = './';
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  useEffect(() => {
    // componentDidMount
    //Google report page
    ReportPageView(EnumPagesTitles.AccountManagement);
  }, []);

  useEffect(() => {
    setLoadedView(true);
  }, []);

  useEffect(() => {
    setLoadedAdminView(true);
  }, []);

  useEffect(() => {
    if (tabIndex === 0 && !window.location.pathname.includes('admin-management')) {
      history.push(`/admin-management?sortBy=${masterOrderOptionName}&page=${masterPage + 1}`);
    }

    if (tabIndex === 1 && !window.location.pathname.includes('users-management')) {
      history.push(`/users-management?sortBy=${userOrderOptionName}&page=${userPage + 1}`);
    }
  }, [tabIndex]);

  useEffect(() => {
    if (totalCount > 0) {
      setTotalNumAccounts(totalCount);
    }
  }, [totalCount]);

  useEffect(() => {
    if (totalCountAdmin > 0) {
      setTotalNumAccounts(totalCountAdmin);
    }
  }, [totalCountAdmin]);

  useEffect(() => {
    if (showTabs) {
      const urlPath = window.location.pathname;
      onChangeUrl(urlPath);
    }
  }, [showTabs]);

  useEffect(() => {
    // Changes tab ONLY when back button is pressed
    window.onpopstate = (e: any) => {
      onChangeUrl(e.currentTarget.location.pathname);
      onChangeUrlPageNumberSortBy(e.currentTarget.location.search, e.currentTarget.location.pathname);
    };
  }, []);

  const onChangeUrlPageNumberSortBy = (search: string, location: string) => {
    const params = search.slice(1).split('&');
    const page = params.find(element => element.includes('page'))?.split('=')[1];
    const previousPage = Number(page) || 1;
    const sort = params.find(element => element.includes('sortBy'))?.split('=')[1];
    const previousSort = sort?.split('%20').join(' ');

    if (location.includes('admin-management')) {
      setMasterPage(previousPage - 1);
      const value = changeMasterSortByPagination({ sortBy: previousSort || 'Newest - Oldest' }, true);
      getMasterData({ page: previousPage - 1, sortBy: value });
    } else {
      setUserPage(previousPage - 1);
      const value = changeUserSortByPagination({ sortBy: previousSort || 'Newest - Oldest' }, true);
      getUsersData({ page: previousPage - 1, sortBy: value });
    }
  };

  // Only called when tab in manually changed
  const changeOfTab = (index: number) => {
    setTabIndex(index);
    setUserPage(0);
    setMasterPage(0);
    if (index === 0 && !window.location.pathname.includes('/admin-management')) {
      history.push(`/admin-management`);
      changeMasterSortByPagination({ sortBy: 'Newest - Oldest' });
      setMasterPage(0);
    }
    if (index === 1 && !window.location.pathname.includes('/users-management')) {
      history.push(`/users-management`);
      setUserPage(0);
      changeUserSortByPagination({ sortBy: 'Newest to Oldest' });
    }
  };

  const onChangeUrl = (urlPath: string) => {
    if (urlPath.includes('admin-management')) {
      setTabIndex(0);
      setMaster(true);
    } else if (urlPath.includes('users-management')) {
      setTabIndex(1);
      setMaster(false);
    }
  };

  useEffect(() => {
    setMasterLoadedPage(false);
    if (showMasterTab) {
      if (tabIndex === 0) {
        getMasterData();
      }
    }
  }, [showMasterTab]);

  useEffect(() => {
    setUserLoadedPage(false);
    if (tabIndex === 1) {
      getAdmin();
      getUsersData();
    }
  }, [userFilterByMasterName, showTabs]);

  useEffect(() => {
    let messageTimer = setTimeout(() => {
      setShowNotification(false);
    }, 3000);
    return () => {
      clearTimeout(messageTimer);
    };
  }, [showNotification]);

  const handleUserPaginationSearch = (pageSelected: IPagination) => {
    setUserPage(pageSelected.selected);
    getUsersData({ page: pageSelected.selected });
  };

  const handleMasterPaginationSearch = (pageSelected: IPagination) => {
    setMasterPage(pageSelected.selected);
    getMasterData({ page: pageSelected.selected });
  };

  const handlerSetSearchBarValue = (keywordValue: string) => {
    setKeyword(keywordValue);
  };

  const handlerSetSearchBarValueAdmin = (keywordValue: string) => {
    setKeywordAdmin(keywordValue);
  };

  const handlerSearching = (isSearchbarLoaded: boolean, page: number) => {
    if (master) {
      setMasterLoadedPage(isSearchbarLoaded);
      getMasterData({ page: page });
    } else {
      setUserLoadedPage(isSearchbarLoaded);
      setUserPage(page);
      getUsersData({ page: page });
    }

    ReportCustomEvent(EnumCategory.Header, EnumEvent.SearchForAccounts);
  };

  const goToCreateAccount = () => {
    ReportCustomEvent(EnumCategory.AccountMenu, EnumEvent.EditAccount);
    history.push(`/create-user`);
  };

  const goToEditAdmin = (account?: IUserAccount) => {
    ReportCustomEvent(EnumCategory.AccountMenu, EnumEvent.EditAccount);
    history.push(`/edit-admin?partnerId=${account?.partnerId}`);
  };

  const goToEditUsers = (account?: IUserAccount) => {
    ReportCustomEvent(EnumCategory.AccountMenu, EnumEvent.EditAccount);
    history.push(`/edit-user?userId=${account?.id}`);
  };

  const panelHooksStyle = {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'visible',
  };

  const toggleCustomDropdown = (option?: boolean) => {
    if (option) {
      setShowCustomDropDown(option);
    } else {
      setShowCustomDropDown(!showCustomDropDown);
    }
  };
  const toggleCustomDropdownAdmin = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownAdmin(option);
    } else {
      setShowCustomDropDownAdmin(!showCustomDropDownAdmin);
    }
  };

  const changePaginationPage = (page: IPagination) => {
    const sortByUrl = params.get('sortBy');
    if (sortByUrl) {
      history.push(`/admin-management?sortBy=${orderOptionName}&page=${page.selected + 1}`);
    } else {
      history.push(`/admin-management?page=${page.selected + 1}`);
    }

    handleMasterPaginationSearch(page);
  };
  const changePaginationPageUser = (page: IPagination) => {
    const sortByUrl = params.get('sortBy');
    if (sortByUrl) {
      history.push(`/users-management?sortBy=${orderOptionName}&page=${page.selected + 1}`);
    } else {
      history.push(`/users-management?page=${page.selected + 1}`);
    }

    handleUserPaginationSearch(page);
  };

  const activeTooltipHandler = () => {
    //Report Custom Category and Event
    ReportCustomEvent(EnumCategory.Tooltip, EnumEvent.TooltipDashboard);
    accountManagementRef.current.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => setIsTooltipClicked(!isTooltipClicked), 300);
  };

  const changeUserSortByPagination = (option: IChangeSortPagination, returnValue?: boolean) => {
    const obj: IChangeSortPagination = {};
    if (option.sortBy) {
      const [value, name] = onChangeSortBy(option.sortBy);
      setUserOrderOption(value);
      setUserOrderOptionName(name);
      if (returnValue) {
        return value;
      }
      obj.sortBy = value;
    }
    if (option.page) {
      setUserPage(option.page);
      obj.page = option.page;
    }

    if (!returnValue && window.location.pathname.includes('users-management')) {
      getUsersData(obj);
    }
  };

  const changeMasterSortByPagination = (option: IChangeSortPagination, returnValue?: boolean) => {
    const obj: IChangeSortPagination = {};
    if (option.sortBy) {
      const [value, name] = onChangeSortByAdmin(option.sortBy);
      setMasterOrderOption(value);
      setMasterOrderOptionName(name);
      if (returnValue) {
        return value;
      }
      obj.sortBy = value;
    }
    if (option.page) {
      setMasterPage(option.page);
      obj.page = option.page;
    }
    if (!returnValue && window.location.pathname.includes('admin-management')) {
      getMasterData(obj);
    }
  };

  return (
    <div className='dashboard-layout'>
      {/* <Steps
        enabled={isTooltipClicked}
        steps={!displayUserEditCreateAccount ? accountManagementSteps : accountToEdit ? editAccountSteps : createAccountSteps}
        initialStep={currentTooltipStep}
        onAfterChange={stepsInfo}
        onComplete={onStepsCompleted}
        onExit={onExit}
        options={{ hideNext: false, exitOnOverlayClick: false, showStepNumbers: false, showBullets: false, disableInteraction: true }}
      /> */}
      <NavSidebar isTooltipActive={false} activeTooltipHandler={activeTooltipHandler} isToolTipClicked={isTooltipClicked} />
      <div ref={accountManagementRef} className='dashboard'>
        {showTabs && (
          <>
            {showMasterTab ? (
              <div className='content-wrapper-account-management'>
                <Tabs changeOfTab={changeOfTab} tabIndex={tabIndex}>
                  <div className='header-AM'>
                    <div className='AM-header-tabs-container'>
                      <div id='users-panel' style={{ cursor: 'pointer' }} onClick={() => setMaster(true)}>
                        <TabHooks>
                          <Trans id='Masters' />
                        </TabHooks>
                      </div>
                      <div id='masters-panel' style={{ cursor: 'pointer' }} onClick={() => setMaster(false)}>
                        <TabHooks>
                          <Trans id='Users' />
                        </TabHooks>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: master ? '/create-admin' : '/create-user',
                      }}
                    >
                      <button id='am-create-account-btn' className='btn-create-new-account button-hover'>
                        <Trans id='Create a new account' render={({ translation }) => <p className='btn-am-text'>{translation}</p>} />
                      </button>
                    </Link>
                  </div>
                  <div className='AM-data-container' style={{ cursor: 'default' }}>
                    <PanelHooks inLineStyle={panelHooksStyle}>
                      <AdminsAccountManagement
                        totalNumAccounts={totalNumAccounts}
                        keyword={keywordAdmin}
                        handlerSetSearchBarValue={handlerSetSearchBarValueAdmin}
                        handlerSearching={handlerSearching}
                        loadedView={loadedAdminView}
                        goToEditCreateAccount={goToCreateAccount}
                        goToEditAdmin={goToEditAdmin}
                        totalCount={totalCountAdmin}
                        orderOptionName={masterOrderOptionName}
                        showCustomDropDown={showCustomDropDownAdmin}
                        toggleCustomDropdown={toggleCustomDropdownAdmin}
                        handleConnectUser={handleConnectUser}
                        changeMasterSortByPagination={changeMasterSortByPagination}
                        userPage={masterPage}
                        userPageCount={masterPageCount}
                        handleUserPaginationSearch={handleMasterPaginationSearch}
                        masterData={masterData}
                        masterLoadedPage={masterLoadedPage}
                      />
                    </PanelHooks>
                    <PanelHooks inLineStyle={panelHooksStyle}>
                      <UsersAccountManagement
                        goToEditUsers={goToEditUsers}
                        totalNumAccounts={totalNumAccounts}
                        keyword={keyword}
                        handlerSetSearchBarValue={handlerSetSearchBarValue}
                        handlerSearching={handlerSearching}
                        loadedView={loadedView}
                        goToEditCreateAccount={goToCreateAccount}
                        totalCount={totalCount}
                        orderOptionName={userOrderOptionName}
                        showCustomDropDown={showCustomDropDown}
                        toggleCustomDropdown={toggleCustomDropdown}
                        handleConnectUser={handleConnectUser}
                        changeUserSortByPagination={changeUserSortByPagination}
                        userPage={userPage}
                        userPageCount={userPageCount}
                        handleUserPaginationSearch={handleUserPaginationSearch}
                        usersData={usersData}
                        setUserFilterByMasterName={setUserFilterByMasterName}
                        userLoadedPage={userLoadedPage}
                        userFilterByMasterName={userFilterByMasterName}
                        adminsNames={adminsNames}
                        setUserPage={setUserPage}
                        setUserOrderOptionName={setUserOrderOptionName}
                      />
                    </PanelHooks>
                  </div>
                  <div className='pagination-container am-pagination-container'>
                    {masterData && masterData.length !== 0 && tabIndex === 0 && (
                      <Pagination page={masterPage} pageCount={masterPageCount} handlePaginationSearch={changePaginationPage} />
                    )}
                    {usersData && usersData.length !== 0 && tabIndex === 1 && (
                      <Pagination page={userPage} pageCount={userPageCount} handlePaginationSearch={changePaginationPageUser} />
                    )}
                  </div>
                </Tabs>
              </div>
            ) : (
              <div className='content-wrapper-account-management'>
                <div className='header-AM'>
                  <div className='AM-header-tabs-container'></div>
                  <button id='am-create-account-btn' className='btn-create-new-account button-hover' onClick={() => goToCreateAccount()}>
                    <Trans id='Create a new account' render={({ translation }) => <p className='btn-am-text'>{translation}</p>} />
                  </button>
                </div>
                <div className='AM-data-container'>
                  <UsersAccountManagement
                    totalNumAccounts={totalNumAccounts}
                    goToEditUsers={goToEditUsers}
                    keyword={keyword}
                    handlerSetSearchBarValue={handlerSetSearchBarValue}
                    handlerSearching={handlerSearching}
                    loadedView={loadedView}
                    goToEditCreateAccount={goToCreateAccount}
                    totalCount={totalCount}
                    orderOptionName={userOrderOptionName}
                    showCustomDropDown={showCustomDropDown}
                    toggleCustomDropdown={toggleCustomDropdown}
                    handleConnectUser={handleConnectUser}
                    changeUserSortByPagination={changeUserSortByPagination}
                    userPage={userPage}
                    userPageCount={userPageCount}
                    handleUserPaginationSearch={handleUserPaginationSearch}
                    usersData={usersData}
                    setUserFilterByMasterName={setUserFilterByMasterName}
                    userLoadedPage={userLoadedPage}
                    userFilterByMasterName={userFilterByMasterName}
                    adminsNames={adminsNames}
                    setUserPage={setUserPage}
                    setUserOrderOptionName={setUserOrderOptionName}
                  />
                </div>
                <div className='pagination-container am-pagination-container'>
                  {usersData && usersData.length !== 0 && tabIndex === 1 && (
                    <Pagination page={userPage} pageCount={userPageCount} handlePaginationSearch={changePaginationPageUser} />
                  )}
                </div>
              </div>
            )}
          </>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default AccountManagement;
