import React, { useState, useRef } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { ValidationUtils } from '../validations/validations';
import { Trans, TransRenderProps } from '@lingui/react';
import { Tabs, TabHooks, PanelHooks } from '../../utils/tabs/tabs';
import { ReactComponent as CloseButton } from '../../../images/icons/modal-icons/close-modal.svg';
import { ReactComponent as SuccessIcon } from '../../../images/icons/modal-icons/success-icon.svg';
import { ReactComponent as TwitterIcon } from '../../../images/icons/modal-icons/twitter-icon.svg';
import { ReactComponent as FacebookIcon } from '../../../images/icons/modal-icons/facebook-icon.svg';
import showPasswordIcon from '../../../images/icons/password-hidden.svg';
import hidePasswordIcon from '../../../images/icons/password-shown.svg';
import { ReactComponent as CopyLink } from '../../../images/icons/modal-icons/copy-link-icon.svg';
import './modal-dashboard.scss';
import { protectTour, unProtectTour } from '../../../api-helper/api-tours';
import { EnumNotificationType } from '../notifications/notification';
import { EnumDollhouseStatus, IModalContent } from '../tour-views/interface';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import { EnumTabsState } from '../../main/dashboard/dashboard';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';

interface IProps {
  hideModal: () => void;
  modalContent: IModalContent;
  orderDollhouseButtonEnabled?: boolean;
  deleteTourMatch?: () => void;
  shareTourLink?: (tourId: string, recipient: string[]) => void;
  getToursAsync?: () => void;
  getUsersToursAsync?: () => void;
  moveTourToNewAccount?: (newEmail: string, tourId: string) => void;
  toggleNotification?: (type: EnumNotificationType, message?: string) => void;
  orderDollhouseTourId?: (tourId: string, backUrl: string, panoramaCount: number) => void;
  reassignDollhouse?: (tourId: string, email: string, status?: EnumDollhouseStatus) => void;
  liveTourTab?: EnumTabsState;
  loadFloorplan?: () => void;
}

const standAloneViewerUrl = window.ENV.REACT_APP_STANDALONE_VIEWER_URL || process.env.REACT_APP_STANDALONE_VIEWER_URL;

const Modal: React.FC<IProps> = ({
  liveTourTab,
  hideModal,
  modalContent,
  orderDollhouseButtonEnabled,
  deleteTourMatch,
  shareTourLink,
  getToursAsync,
  getUsersToursAsync,
  moveTourToNewAccount,
  toggleNotification,
  orderDollhouseTourId,
  reassignDollhouse,
}) => {
  const { handleLogoutState } = useAuth();
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [recipientEmail, setRecipientEmail] = useState<any>('');
  const [shareEmailActive, setShareEmailActive] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [deleteActive, setDeleteActive] = useState<boolean>(false);
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const urlTextAreaRef = useRef<HTMLInputElement | null>(null);
  const embedTextAreaRef = useRef<any>(null);
  const [shareEmailInputValue, setShareEmailInputValue] = useState<string>('');
  const [isValidEmailsToShare, setIsValidEmailsToShare] = useState<boolean>(false);
  const [isPasswordProtected, setIsPasswordProtected] = useState<boolean>(false);
  const [isUnprotected, setIsUnprotected] = useState<boolean>(true);
  const [copyClickedEmbed, setCopyClickedEmbed] = useState<boolean>(false);

  const refreshCorrespondingTab = () => {
    if (liveTourTab === EnumTabsState.LiveTours) {
      if (getToursAsync) {
        getToursAsync();
      }
    } else if (liveTourTab === EnumTabsState.UsersLiveTours) {
      if (getUsersToursAsync) {
        getUsersToursAsync();
      }
    }
  };

  const modalBackgroundLayer = <div className='full-screen' onClick={() => hideModal()}></div>;

  const displayInfoMessage = (type: string) => {
    if (type === 'email-share') {
      <Trans id='Your tour has been sent!' render={({ translation }) => <p id='alert-text'>{translation}</p>} />;
      return (
        <div className='alert-info-message'>
          <SuccessIcon id='alert-icon' />
          <Trans id='Your tour has been sent!' render={({ translation }) => <p id='alert-text'>{translation}</p>} />
        </div>
      );
    } else if (type === 'password-protected') {
      return (
        <div className='alert-info-message'>
          <SuccessIcon id='alert-icon' />
          <Trans id='Your tour has been password protected!' render={({ translation }) => <p id='alert-text'>{translation}</p>} />
        </div>
      );
    } else if (type === 'remove-password-protected') {
      return (
        <div className='alert-info-message'>
          <SuccessIcon id='alert-icon' />
          <Trans id='Your password has been removed!' render={({ translation }) => <p id='alert-text'>{translation}</p>} />
        </div>
      );
    }
  };

  const deleteTour = () => {
    return (
      <React.Fragment>
        <div className='modal-header'>
          <p className='modal-header-title'>{modalContent.title}</p>
          <button onClick={() => hideModal()} className='btn-remove-default' id='close-modal-button'>
            <CloseButton />
          </button>
        </div>
        <div className='modal-body'>
          <div className='delete-tour-container'>
            <div className='delete-tour-text-action'>
              <Trans
                id="You're about to delete <0>{tourName}</0>"
                values={{ tourName: modalContent.tourName }}
                components={{ 0: <b></b> }}
              />
            </div>
            <div className='delete-tour-text-info'>
              <Trans
                id='Deleted tours will be kept in the <0>Archived Tours</0> section for 30 days before being <1>permanently deleted</1>'
                components={{ 0: <b></b>, 1: <b style={{ color: '#ff0001' }}></b> }}
              />
            </div>
            <div className='delete-tour-text-confirmation'>
              <Trans
                id='To confirm, type <0>{tourName}</0> below'
                values={{ tourName: modalContent.tourName }}
                components={{ 0: <b></b> }}
              />
            </div>
            <input
              type='text'
              className='delete-tour-input'
              placeholder={modalContent.tourName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDeleteActive(e.target.value === modalContent.tourName ? true : false);
              }}
            />
            <button
              className={`delete-tour-button ${deleteActive ? 'btn-active button-hover' : 'btn-inactive'}`}
              onClick={() => (deleteActive ? (deleteTourMatch ? deleteTourMatch() : null) : null)}
            >
              <Trans id='Delete tour' />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const shareLink = () => {
    const validateEmailsToShare = (e: React.ChangeEvent<HTMLInputElement>) => {
      const emails = e.target.value;
      setShareEmailInputValue(emails);
      const emailsArray: any = emails?.split(/[ ,]+/);
      const validatedEmailsArray = [];
      let validationEmails: boolean = false;
      // Validate emails
      for (const email of emailsArray) {
        validationEmails = ValidationUtils.isEmail(email);
        if (ValidationUtils.isEmail(email)) {
          validatedEmailsArray.push(email);
        }
      }
      setRecipientEmail(validatedEmailsArray);
      if (validationEmails) {
        setShareEmailActive(true);
      } else {
        setShareEmailActive(false);
      }
    };

    const embedTour = (tour: any): string => {
      const tourUrl = standAloneViewerUrl + tour.readCode;
      return `<iframe id="vieweet-iframe-${tour.readCode}" name="vieweet-iframe-${tour.readCode}" src="${
        tourUrl + '?disableSlider=true'
      }" width="600" height="360" marginwidth="0" marginheight="0" hspace="0" vspace="0" frameborder="0" scrolling="no" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>`;
    };

    return (
      <React.Fragment>
        <div className='modal-header'>
          <p className='modal-header-title'>{modalContent.title}</p>
          <button onClick={() => hideModal()} className='remove-default' id='share-close-modal-button'>
            <CloseButton />
          </button>
        </div>
        <div className='modal-body-share'>
          <Tabs tabIndex={0}>
            <ul className='share-link-tabs'>
              <TabHooks id='tab-share-link'>
                <Trans id='Share Link' />
              </TabHooks>
              <TabHooks id='tab-email-link'>
                <Trans id='Email Link' />
              </TabHooks>
              <TabHooks id='tab-embed-tour'>
                <Trans id='Embed Tour' />
              </TabHooks>
            </ul>
            <div className='share-link-container'>
              <PanelHooks>
                <div className='share-link-content'>
                  <div className='share-link-text'>
                    <Trans
                      id='Share your virtual tour'
                      render={({ translation }) => (
                        <p className='ellipsis-name'>
                          {translation} <strong>{modalContent?.tour?.name}</strong>
                        </p>
                      )}
                    />
                  </div>
                  <div className='share-link-copy'>
                    <input
                      type='text'
                      ref={urlTextAreaRef}
                      readOnly
                      className='copy-link-input'
                      value={standAloneViewerUrl + modalContent?.tour?.readCode || ''}
                    />
                  </div>
                  <div className='share-link-copy-media'>
                    <div className='share-link-social-media'>
                      <div className='social-media-icon'>
                        <TwitterShareButton url={modalContent?.tour?.url || ''} hashtags={['vieweet']}>
                          <TwitterIcon />
                        </TwitterShareButton>
                      </div>
                      <div className='social-media-icon'>
                        <FacebookShareButton url={modalContent?.tour?.url || ''} hashtag='#vieweet'>
                          <FacebookIcon />
                        </FacebookShareButton>
                      </div>
                    </div>
                    <button
                      className='copy-email-button button-hover'
                      onClick={(e: React.MouseEvent): void => {
                        e.preventDefault();
                        urlTextAreaRef.current?.select();
                        document.execCommand('copy');
                      }}
                    >
                      <Trans id='Copy link' />
                    </button>
                  </div>
                </div>
              </PanelHooks>
              <PanelHooks>
                <div className='share-link-email-container'>
                  <div className='share-link-text'>
                    <Trans
                      id='Send via email the virtual tour'
                      render={({ translation }) => (
                        <p className='ellipsis-name'>
                          {translation} <strong>{modalContent?.tour?.name || ''}</strong>
                        </p>
                      )}
                    />
                  </div>
                  {!isValidEmailsToShare ? (
                    <React.Fragment>
                      <div className='share-link-email'>
                        <Trans
                          id={'Send to more email addresses by adding a comma between the emails'}
                          render={({ translation }: any) => (
                            <input
                              className='email-link-input'
                              type='text'
                              placeholder={translation}
                              value={shareEmailInputValue}
                              onChange={e => validateEmailsToShare(e)}
                            />
                          )}
                        />
                        <div>
                          <button
                            disabled={!shareEmailActive}
                            className={`email-link-button ${shareEmailActive ? 'btn-active button-hover' : 'btn-inactive'}`}
                            onClick={() => {
                              if (shareTourLink) {
                                shareTourLink(modalContent?.tour?.id || '', recipientEmail);
                              }
                              setIsValidEmailsToShare(true);
                            }}
                          >
                            <Trans id='Email link' />
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    displayInfoMessage('email-share')
                  )}
                </div>
              </PanelHooks>
              <PanelHooks>
                <div className='share-link-content'>
                  <div className='share-link-embed'>
                    <textarea ref={embedTextAreaRef} className='embed-link-input' value={embedTour(modalContent.tour)} readOnly />
                    <button
                      onClick={(e: React.MouseEvent): void => {
                        e.preventDefault();
                        embedTextAreaRef.current?.select();
                        document.execCommand('copy');
                        setCopyClickedEmbed(true);
                      }}
                      id='viewer-embed-copy'
                      className={`${!copyClickedEmbed ? 'copy-inactive' : 'copy-active'} copy-link-embed-icon remove-default`}
                    >
                      <CopyLink />
                      <Trans id='copy' />
                    </button>
                  </div>
                </div>
              </PanelHooks>
            </div>
          </Tabs>
        </div>
      </React.Fragment>
    );
  };

  const changeDollhouseStatus = () => {
    return (
      <React.Fragment>
        <div className='modal-header'>
          <p className='modal-header-title'>{modalContent.title}</p>
          <button onClick={() => hideModal()} className='remove-default' id='close-modal-button'>
            <CloseButton />
          </button>
        </div>
        <div className='modal-body'>
          <div className='share-link-container'>
            <div className='share-link-content'>
              <div className='share-link-text'>
                {modalContent.status === EnumDollhouseStatus.Pending ? (
                  <Trans id='Email to user you would like to assign this dollhouse to' />
                ) : (
                  <Trans id='Email to user you would like to reassign this dollhouse to' />
                )}
              </div>
              <div className='share-link-email'>
                <input
                  className='email-link-input'
                  type='text'
                  placeholder='email'
                  value={email}
                  onChange={e => {
                    if (ValidationUtils.isEmail(e.target.value)) {
                      setValidEmail(true);
                    } else {
                      setValidEmail(false);
                    }
                    setEmail(e.target.value);
                  }}
                />
                <button
                  disabled={!validEmail}
                  className={`email-link-button ${validEmail ? 'btn-active button-hover' : 'btn-inactive'}`}
                  onClick={() => {
                    if (reassignDollhouse) {
                      reassignDollhouse(modalContent.tourId || '', email, modalContent.newStatus || undefined);
                    }
                  }}
                >
                  {modalContent.status === EnumDollhouseStatus.Pending ? <Trans id='Assign' /> : <Trans id='Reassign' />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const passwordProtectTour = async (password: string) => {
    try {
      await protectTour(modalContent?.tourId || '', password);
      setIsPasswordProtected(true);
      refreshCorrespondingTab();
    } catch (error) {
      if (toggleNotification) {
        toggleNotification(EnumNotificationType.Error);
      }
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const removePasswordProtectTour = async (tourId: string) => {
    try {
      await unProtectTour(tourId);
      setIsUnprotected(false);
      refreshCorrespondingTab();
    } catch (error) {
      if (toggleNotification) {
        toggleNotification(EnumNotificationType.Error);
      }
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const orderDollhouse = () => {
    return (
      <React.Fragment>
        <div className='modal-header'>
          <p className='modal-header-title'>{modalContent.title}</p>
          <button
            onClick={() => {
              hideModal();
              ReportCustomEvent(EnumCategory.Dollhouse, EnumEvent.CancelDollhouseOrder);
            }}
            className='btn-remove-default'
            id='close-modal-button'
          >
            <CloseButton />
          </button>
        </div>
        <div className='modal-body'>
          <div className='password-protect-text'>
            <div style={{ marginBottom: '15px' }}>
              {modalContent?.apiNoPayment !== undefined && !modalContent.apiNoPayment && (
                <div>
                  <b>One-off fee and no hosting fee.</b>
                </div>
              )}
            </div>
            <Trans
              id='You’re about to order the floor plan & dollhouse for '
              values={{ tourName: modalContent?.tourName }}
              components={{ 0: <strong></strong> }}
            />
            <br />
            <b>{modalContent?.tourName}</b>
            <div>Do you want to proceed?</div>
          </div>
          <div style={{ display: 'flex', gap: '40px', position: 'relative' }}>
            <button
              style={{ minWidth: '173px' }}
              className='btn-new-design button-hover'
              onClick={() => {
                hideModal();
                ReportCustomEvent(EnumCategory.Dollhouse, EnumEvent.CancelDollhouseOrder);
              }}
            >
              <Trans id='Cancel' />
            </button>
            <button
              style={{ width: '173px' }}
              className={`btn-new-design ${!orderDollhouseButtonEnabled ? 'btn-new-inactive' : 'btn-new-active button-hover'}`}
              disabled={!orderDollhouseButtonEnabled}
              onClick={() => {
                if (orderDollhouseTourId) {
                  orderDollhouseTourId(modalContent?.tourId || '', modalContent?.backUrl || '', modalContent.panoramaCount || 0);
                }
                ReportCustomEvent(EnumCategory.Dollhouse, EnumEvent.ConfirmeOrderDollhouse);
              }}
            >
              <Trans id='Confirm order' />
            </button>
            {!orderDollhouseButtonEnabled && (
              <div className='uploading-image-spinner-order'>
                <i className='fa fa-spinner fa-spin fa-2x fa-fw'></i>
                <span className='sr-only'>
                  <Trans id='Loading...' />
                </span>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const passwordProtect = () => {
    return (
      <React.Fragment>
        <div className='modal-header'>
          <p className='modal-header-title'>{modalContent.title}</p>
          <button onClick={() => hideModal()} className='btn-remove-default' id='close-modal-button'>
            <CloseButton />
          </button>
        </div>
        <div className='modal-body'>
          <div className='password-protect-text'>
            <Trans
              id='The virtual tour <0>{tourName}</0> will be password protected'
              values={{ tourName: modalContent?.tourName }}
              components={{ 0: <strong></strong> }}
            />
          </div>
          {!isPasswordProtected ? (
            <React.Fragment>
              <div className='password-protect-input-container'>
                <Trans
                  id='password'
                  render={({ translation }: TransRenderProps) => (
                    <input
                      id='input-modal-password'
                      className='password-protect-input'
                      name='password'
                      type={passwordShown ? 'text' : 'password'}
                      placeholder={translation as string}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPassword(e.target.value);
                      }}
                    />
                  )}
                />
                <button
                  data-testid='password-toggle'
                  onClick={(e: React.MouseEvent): void => {
                    e.preventDefault();
                    setPasswordShown(!passwordShown);
                  }}
                  className='password-protect-show-icon remove-default'
                >
                  <img src={passwordShown ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
                </button>
              </div>
              <button
                className={`protect-password-button ${password !== '' ? 'btn-active button-hover' : 'btn-inactive'}`}
                onClick={() => {
                  if (password !== '') {
                    passwordProtectTour(password);
                  }
                }}
              >
                <Trans id='Protect with password' />
              </button>
            </React.Fragment>
          ) : (
            displayInfoMessage('password-protected')
          )}
        </div>
      </React.Fragment>
    );
  };

  const removePasswordProtect = () => {
    return (
      <React.Fragment>
        <div className='modal-header'>
          <p className='modal-header-title'>{modalContent.title}</p>
          <button onClick={() => hideModal()} className='btn-remove-default' id='close-modal-button'>
            <CloseButton />
          </button>
        </div>
        <div className='modal-body'>
          <div className='password-protect-text'>
            <Trans
              id='After removing the password the virtual tour <0>{tourName}</0> will be visible to anyone'
              values={{ tourName: modalContent.tourName }}
              components={{ 0: <strong></strong> }}
            />
          </div>
          {isUnprotected ? (
            <button
              className='remove-protect-password-button btn-active button-hover'
              onClick={() => {
                removePasswordProtectTour(modalContent?.tourId || '');
              }}
            >
              <Trans id='Remove password' />
            </button>
          ) : (
            displayInfoMessage('remove-password-protected')
          )}
        </div>
      </React.Fragment>
    );
  };

  const moveTour = () => {
    return (
      <React.Fragment>
        <div className='modal-header'>
          <p className='modal-header-title'>{modalContent.title}</p>
          <button onClick={() => hideModal()} className='btn-remove-default' id='close-modal-button'>
            <CloseButton />
          </button>
        </div>
        <div className='modal-body'>
          <div className='password-protect-text'>
            <Trans
              id='Enter the account email address in the field below to transfer the tour <0>{tourName}</0> from {email} to a new account'
              values={{ tourName: modalContent.tourName, email: modalContent.userEmail }}
              components={{ 0: <strong></strong> }}
            />
          </div>
          <input
            type='text'
            className='move-tour-input'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
              if (ValidationUtils.isEmail(e.target.value)) {
                setEmailValid(true);
              } else {
                setEmailValid(false);
              }
            }}
          />
          <div style={{ display: 'flex', gap: '40px' }}>
            <button style={{ width: '173px' }} className='btn-new-design btn-new-cancel button-hover' onClick={() => hideModal()}>
              <Trans id='Cancel' />
            </button>
            <button
              style={{ width: '173px' }}
              className={`btn-new-design ${emailValid ? 'btn-new-active button-hover' : 'btn-new-inactive'}`}
              onClick={() => {
                if (emailValid) {
                  if (moveTourToNewAccount) {
                    moveTourToNewAccount(email || '', modalContent?.tourId || '');
                  }
                }
              }}
            >
              <Trans id='Transfer tour' />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <React.Fragment>
        {modalBackgroundLayer}
        <div className='modal'>
          {modalContent.id === 'delete-tour'
            ? deleteTour()
            : modalContent.id === 'password-protect'
            ? passwordProtect()
            : modalContent.id === 'move-tour'
            ? moveTour()
            : modalContent.id === 'order-dollhouse'
            ? orderDollhouse()
            : modalContent.id === 'change-dollhouse-status'
            ? changeDollhouseStatus()
            : modalContent.id === 'share-tour'
            ? shareLink()
            : removePasswordProtect()}
        </div>
      </React.Fragment>
    </>
  );
};

export default Modal;
