import React, { useEffect, useState } from 'react';
import { ReactComponent as SettingsIcon } from '../../images/viewer-icons/settings.svg';
import { ReactComponent as ShareIcon } from '../../images/viewer-icons/share.svg';
import { ReactComponent as ViewTour } from '../../images/viewer-icons/view-tour.svg';
import { ReactComponent as LogOut } from '../../images/viewer-icons/log-out.svg';
import proVieweetLogo from '../../images/general-icons/white-v.svg';
import freeVieweetLogo from '../../images/general-icons/free-vieweet-logo.svg';
import { updatePanorama } from '../../api-helper/api-panoramas';
// import { FilterableDropdownList } from '@vva/front';
import { useConfig } from '../utils/user-config-context/user-config-context';
import { ReportCustomEvent } from '../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../google-analytics-track-list';
import { Trans } from '@lingui/react';
import CustomSelect from '../utils/custom-select/custom-select';
import { defineMessage } from '@lingui/macro';
import { ISidebar } from './viewer-canvas';
import { linguiTranslateToString } from '../utils/lingui-utils/utils-lingui';
import FilterableDropdownList from '../utils/dropdown-front/filterable-drop-down-list';

interface IProps {
  sidebar: ISidebar;
  currentPanorama: any;
  changeLeftSidebar: (option: string) => void;
  tour: any;
  updatePanoramaList: () => void;
  exitEditor: Function;
  activeTooltipHandler: () => void;
  isTooltipActive: boolean;
}

interface IUpdateBody {
  name?: string;
  level?: number;
  outdoor?: boolean;
}

const Header: React.FC<IProps> = ({
  sidebar,
  currentPanorama,
  changeLeftSidebar,
  tour,
  updatePanoramaList,
  exitEditor,
  activeTooltipHandler,
  isTooltipActive,
}) => {
  const config = useConfig();
  const [labelLevels, setLabelLevels] = useState<any>();
  const [panoramaInputs, setPanoramaInputs] = useState<any>();
  const [showCustomDropDownFloor, setShowCustomDropDownFloor] = useState<boolean>(false);
  const [showCustomDropDownIndoor, setShowCustomDropDownIndoor] = useState<boolean>(false);

  useEffect(() => {
    if (tour && tour.id) {
      if (config && config.roomLabels && config.roomLevels) {
        const labels = {
          roomLabels: config.roomLabels,
          roomLevel: config.roomLevels,
        };
        setLabelLevels(labels);
      }
    }
  }, [tour, config]);

  const updatePanoramaLevelOrName = async (body: IUpdateBody) => {
    try {
      await updatePanorama(panoramaInputs.id, body);
      updatePanoramaList();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentPanorama && currentPanorama.id) {
      const outdoor = currentPanorama?.panoramaOptions.find((option: any) => option.panoramaKey === 'OUTDOOR')?.panoramaValue;

      setPanoramaInputs({
        id: currentPanorama.id,
        level: currentPanorama.level || 0,
        name: currentPanorama.name || '',
        outdoor: outdoor || false,
      });
    }
  }, [currentPanorama]);

  const handleSelectedLabel = async (selectedValue: any) => {
    if (selectedValue && selectedValue.length && selectedValue[0].name) {
      const outdoor = checkIfNameIsOutdoor(selectedValue[0].name);
      updatePanoramaLevelOrName({ name: selectedValue[0].name, outdoor: outdoor ? true : false });
      setPanoramaInputs({ ...panoramaInputs, name: selectedValue[0].name, outdoor: outdoor ? true : false });
    }
  };

  const checkIfNameIsOutdoor = (newName: string) => {
    const outdoorNames = ['garden', 'balcony', 'patio', 'terrace', 'front elevation', 'communal corridor', 'lift area'];
    const outdoor = outdoorNames.some(name => newName.toLowerCase().includes(name));
    if (outdoor) {
      return true;
    }
  };

  const OpenExternalViewer = () => {
    const standAloneViewerUrl = window.ENV.REACT_APP_STANDALONE_VIEWER_URL || process.env.REACT_APP_STANDALONE_VIEWER_URL;
    return standAloneViewerUrl + tour.readCode;
  };

  const selectNewPanoramaName = (e: any) => {
    setPanoramaInputs({ ...panoramaInputs, name: e.target.value });
    updatePanoramaLevelOrName({ name: e.target.value });
  };

  const onChangeSortLevel = (floor: number) => {
    setPanoramaInputs({ ...panoramaInputs, level: floor });
    setShowCustomDropDownFloor(false);
    updatePanoramaLevelOrName({ level: floor });
  };

  const onChangeSortIndoorOutdoor = (outdoor: string) => {
    setPanoramaInputs({ ...panoramaInputs, outdoor: outdoor === 'Indoor' ? false : true });
    updatePanoramaLevelOrName({ outdoor: outdoor === 'Indoor' ? false : true });
    setShowCustomDropDownIndoor(false);
  };

  const toggleCustomFloor = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownFloor(option);
    } else {
      setShowCustomDropDownFloor(!showCustomDropDownFloor);
    }
  };

  const toggleCustomIndoorOutdoor = (option?: boolean) => {
    if (option) {
      setShowCustomDropDownIndoor(option);
    } else {
      setShowCustomDropDownIndoor(!showCustomDropDownIndoor);
    }
  };

  return (
    <React.Fragment>
      <div className='edit-menu-icon'>
        <div
          className='vieweet-icon-studio'
          onClick={() => {
            exitEditor();
          }}
        >
          {sidebar.proLogo ? <img src={proVieweetLogo} alt='vieweet' /> : <img src={freeVieweetLogo} alt='vieweet' />}
        </div>
      </div>
      <div
        onClick={activeTooltipHandler}
        className={`tooltip-icon tooltip-editor ${isTooltipActive ? 'tooltip-light-active' : 'tooltip-light'}`}
      />

      <div className='display-flex' style={{ marginLeft: '200px' }}>
        <div
          className='label-dropdown'
          id='room-label'
          onClick={() => {
            //Report Custom Category and Event
            ReportCustomEvent(EnumCategory.Header, EnumEvent.ChangeLabel);
          }}
        >
          <FilterableDropdownList
            selectedProps={panoramaInputs && panoramaInputs.name ? [panoramaInputs.name] : ['Room label']}
            dataset={labelLevels && labelLevels.roomLabels ? labelLevels.roomLabels : []}
            allowNew={false}
            isDisabled={false}
            labelKey={'name'}
            handleSelectedItem={handleSelectedLabel}
            IsResultHighlightOnly={false}
            selectNewOnBlur={selectNewPanoramaName}
          />
        </div>
        <div className={`${showCustomDropDownFloor ? 'floor-custom-container-focus' : 'floor-number'}`} id='floor-number'>
          <div
            className={`floor-custom-container`}
            onClick={() => {
              //Report Custom Category and Event
              ReportCustomEvent(EnumCategory.Header, EnumEvent.ChangeFloor);
            }}
          >
            <div className='label-floor'>
              <Trans id='Floor' />
            </div>
            <CustomSelect
              value={panoramaInputs?.level ? String(panoramaInputs?.level) : '0'}
              options={(labelLevels && labelLevels.roomLevel && labelLevels.roomLevel.map((level: number) => level)) || []}
              changeDropdownValue={onChangeSortLevel}
              showCustomDropDown={showCustomDropDownFloor}
              setShowCustomDropDown={toggleCustomFloor}
              customClass={'custom-floor'}
              customArrow={'arrow-floor'}
              customDropdown={'dropdown-floor-indoor'}
            />
          </div>
        </div>
        <div className={` ${showCustomDropDownIndoor ? 'floor-number-indoor-focus' : 'floor-number-indoor'}`} id='floor-number'>
          <div
            className='floor-custom-container'
            onClick={() => {
              //Report Custom Category and Event
              ReportCustomEvent(EnumCategory.Header, EnumEvent.ChangeIndoor);
            }}
          >
            <CustomSelect
              placeholder={'In/Outdoor'}
              value={panoramaInputs?.outdoor === false ? linguiTranslateToString('Indoor') : linguiTranslateToString('Outdoor')}
              options={[defineMessage({ message: 'Indoor' }), defineMessage({ message: 'Outdoor' })]}
              changeDropdownValue={onChangeSortIndoorOutdoor}
              showCustomDropDown={showCustomDropDownIndoor}
              setShowCustomDropDown={toggleCustomIndoorOutdoor}
              customClass={'custom-indoor'}
              customArrow={'arrow-indoor'}
              customDropdown={'dropdown-floor-indoor'}
              showWhiteArrow={true}
            />
          </div>
        </div>
      </div>
      <div className='display-flex'>
        {/* {sidebar.klComponents && (
          <div
            className='edit-menu-icon'
            onClick={() => {
              //Report Custom Category and Event
              ReportCustomEvent(EnumCategory.Header, EnumEvent.Publish360);
              changeLeftSidebar('publish');
            }}
          >
            <button id='Publish' className='btn-remove-default'>
              <ViewTour />
            </button>
          </div>
        )} */}
        <div
          className='edit-menu-icon'
          onClick={() => {
            //Report Custom Category and Event
            ReportCustomEvent(EnumCategory.Header, EnumEvent.View360);
          }}
        >
          <button id='view-360-tour' className='btn-remove-default'>
            <a target='_blank' rel='noreferrer' href={OpenExternalViewer()} className='btn-remove-default'>
              <ViewTour />
            </a>
          </button>
        </div>
        <div className='edit-menu-icon'>
          <button
            id='settings-editor'
            className='btn-remove-default'
            onClick={() => {
              ReportCustomEvent(EnumCategory.Header, EnumEvent.OpenSettings);
              changeLeftSidebar('settings');
            }}
          >
            <SettingsIcon />
          </button>
        </div>
        <div className='edit-menu-icon'>
          <button
            id='share-tour-editor'
            className='btn-remove-default'
            onClick={() => {
              ReportCustomEvent(EnumCategory.Header, EnumEvent.ShareTour);
              changeLeftSidebar('share');
            }}
          >
            <ShareIcon />
          </button>
        </div>
        <div className='edit-menu-icon'>
          <button
            id='close-editor'
            className='btn-remove-default'
            onClick={() => {
              ReportCustomEvent(EnumCategory.Header, EnumEvent.BackToDashboard);
              exitEditor();
            }}
          >
            <LogOut />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
