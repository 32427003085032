import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/react';
import { EnumDollhouseStatus, IPropsTourCards } from './interface';
import defaultImage from '../../../images/empty.svg';
import passwordLock from '../../../images/dashboard-icons/password-lock.svg';
import leadsGeneration from '../../../images/dashboard-icons/lead-generation.svg';
import displayLocation from '../../../images/dashboard-icons/display-location.svg';
import linkIconCard from '../../../images/dashboard-icons/card-icons/link-icon-card.svg';
import locationIcon from '../../../images/dashboard-icons/card-icons/icon-location.svg';
import profileIcon from '../../../images/dashboard-icons/profile-icon.svg';
import iconDollHouse from '../../../images/dashboard-icons/icon-dollhouse-pending.svg';
import iconDollHouseCompleted from '../../../images/dashboard-icons/icon-dollhouse-complete.svg';
import './tour-views-dropdown-menu.scss';
import './tour-rows-new.scss';
import './tour-rows.scss';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import { ITours } from '../../../api-helper/interface/interfaces';
import { defineMessage } from '@lingui/macro';
import { linguiTranslateToString } from '../lingui-utils/utils-lingui';

export enum EnumFloorplanMode {
  PLAIN = 'plain',
  LAYERED = 'layered',
  ALL = 'all',
}

const TourRowsDollhouse: React.FC<IPropsTourCards> = ({
  page,
  keyword,
  tours,
  toursRef,
  orderOptionName,
  orderOption,
  connectToUserGoToStudio,
  master,
  restoreTours,
  type,
}) => {
  const getStandaloneViewerUrl = (tourReadCode: string, short: boolean) => {
    const standAloneViewerUrl = window.ENV.REACT_APP_STANDALONE_VIEWER_URL || process.env.REACT_APP_STANDALONE_VIEWER_URL;
    const url = standAloneViewerUrl + tourReadCode;

    if (short) {
      const shortUrl = standAloneViewerUrl.replace(/^https:\/\//i, '');
      return shortUrl && tourReadCode ? shortUrl + tourReadCode : '';
    } else {
      return url ? url : '';
    }
  };

  const get3DViewerUrl = (tourId: string, short: boolean) => {
    const standAloneViewerUrl = window.ENV.REACT_APP_3D_VIEWER_URL || process.env.REACT_APP_3D_VIEWER_URL;
    const url = standAloneViewerUrl + '?tour=' + tourId;

    if (short) {
      const shortUrl = standAloneViewerUrl.replace(/^https:\/\//i, '');
      return shortUrl && tourId ? shortUrl + tourId : '';
    } else {
      return url ? url : '';
    }
  };

  const goTo3DEditor = (tourReadCode: string) => {
    const standAloneViewerUrl = window.ENV.REACT_APP_3D_VIEWER_URL || process.env.REACT_APP_3D_VIEWER_URL;
    const url = standAloneViewerUrl + '?tour=' + tourReadCode + '&mode=edit';
    window.open(url, '_blank');
  };

  const addDefaultSrc = (ev: React.ChangeEvent<HTMLImageElement>) => {
    ev.target.src = defaultImage;
  };

  const getDollhouseStatus = (tour: ITours) => {
    let circleColour;
    let status;
    if (tour.status < EnumDollhouseStatus.Pending) {
      circleColour = '#FF0000';
      status = defineMessage({ message: 'Deleted' });
    } else if (tour.status === EnumDollhouseStatus.Pending) {
      circleColour = '#D50057';
      status = defineMessage({ message: 'To do' });
    } else if (tour.status === EnumDollhouseStatus.Active) {
      circleColour = '#FFDD00';
      status = defineMessage({ message: 'Pending' });
    } else if (tour.status === EnumDollhouseStatus.PendingValidation) {
      circleColour = '#00269A';
      status = defineMessage({ message: 'Awaiting Validation' });
    } else if (tour.status === EnumDollhouseStatus.Validated) {
      circleColour = '#0e0333';
      status = defineMessage({ message: 'Completed' });
    } else if (tour.status === EnumDollhouseStatus.FloorplanSent) {
      circleColour = '#13B597';
      status = defineMessage({ message: 'FP Sent' });
    } else {
      circleColour = '#FF7E00';
      status = defineMessage({ message: 'Unknown' });
    }

    return (
      <div className='info-details-containers'>
        <div className='dots' style={{ background: circleColour }}></div>
        <Trans id={String(status.id)} />
      </div>
    );
  };
  return (
    <React.Fragment>
      {tours?.map((tour: ITours, index: number) => (
        <div id='tours-row' ref={element => (toursRef.current[index] = element)} key={index} className='tour-row'>
          <div
            className='row-image-doll'
            onClick={() => {
              //Report Custom Category and Event
              ReportCustomEvent(EnumCategory.TourInformation, EnumEvent.OpenTourProfile);
            }}
          >
            {!restoreTours && (
              <div className='lock-leads-icons'>
                {tour.protected && (
                  <button style={{ cursor: 'default' }} className=' icon-on-card'>
                    <img title={linguiTranslateToString('Password protected')} src={passwordLock} alt='Padlock password protect' />
                  </button>
                )}
                {tour.shareLocation === '1' && (
                  <button style={{ cursor: 'default' }} className='icon-on-card'>
                    <img title={linguiTranslateToString('Location displayed')} src={displayLocation} alt='Location displayed' />
                  </button>
                )}
                {tour.enableLeadGenerationForm === '1' && (
                  <button style={{ cursor: 'default' }} className='icon-on-card'>
                    <img title={linguiTranslateToString('Leads generation')} src={leadsGeneration} alt='Leads generation' />
                  </button>
                )}
              </div>
            )}
            <div className='edit-link'>
              <Link
                to={{
                  pathname: '/edit-tour/panoramas',
                  search: `?tourId=${tour.tourId}`,
                  state: {
                    ...tour,
                    id: tour.tourId,
                    paginationPage: page,
                    searchWord: keyword,
                    tourIndex: index,
                    displayDashboard: true,
                    orderOptionName: orderOptionName,
                    orderOption: orderOption,
                    dashboardView: 'rows',
                  },
                }}
              >
                <div className='img-icon-card'></div>
                <Trans id='Tour details' />
              </Link>
            </div>
            <img className='img-row-view' onError={addDefaultSrc} src={tour.mainPhotoUrl || defaultImage} alt='Tour preview' />
          </div>
          <div className='info-row-view-doll'>
            <div className='container-title-info-dollhouse'>
              <div id='tour-name' className='tour-row-address'>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={{
                    pathname: '/edit-tour/panoramas',
                    search: `?tourId=${tour.tourId}`,
                    state: { ...tour, id: tour.tourId },
                  }}
                >
                  {tour.name}
                </Link>
              </div>
              <div className='status-dollhouse'>{getDollhouseStatus(tour)}</div>
            </div>
            <div className='container-bottom-info-dollhouse'>
              <div className='container-left-info'>
                <div className='info-details-location'>
                  <img className='details-icons' src={locationIcon} alt='Tour Location icon' />
                  <p title={tour.name} className='text-info-bottom-doll'>
                    {tour.name}
                  </p>
                </div>
                <div
                  className='info-url'
                  onClick={() => {
                    //Report Custom Category and Event
                    ReportCustomEvent(EnumCategory.TourInformation, EnumEvent.OpenTourWithUrl);
                  }}
                >
                  <img className='details-icons' src={linkIconCard} alt='Tour url icon' />
                  {type === 'archived' || type === 'deleted' ? (
                    <p className='text-info-bottom-doll invalid-link'>{getStandaloneViewerUrl(tour.readCode, false)}</p>
                  ) : (
                    <a
                      title={getStandaloneViewerUrl(tour.readCode, false)}
                      className='text-info-bottom-doll'
                      href={getStandaloneViewerUrl(tour.readCode, false)}
                      rel='noreferrer'
                      target='_blank'
                    >
                      {getStandaloneViewerUrl(tour.readCode, false)}
                    </a>
                  )}
                </div>
              </div>
              <div className='container-right-info-dollhouse'>
                <div className='info-email-detail'>
                  <img className='details-icons' src={profileIcon} alt='Tour Location icon' />
                  <p className='text-info-bottom-doll'> {tour.userEmail} </p>
                </div>

                {tour.status < EnumDollhouseStatus.Validated ? (
                  <>
                    <div className='info-url-doll'>
                      <img className='details-icons' src={iconDollHouse} alt='Tour url icon' />
                      <a
                        title={get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                        className='text-info-bottom-invalid-doll'
                        href={get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                        rel='noreferrer'
                        target='_blank'
                      >
                        {get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='info-url-doll'>
                      <img className='details-icons' src={iconDollHouseCompleted} alt='Tour url icon' />
                      <a
                        title={get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                        className='text-info-bottom-doll'
                        href={get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                        rel='noreferrer'
                        target='_blank'
                      >
                        {get3DViewerUrl(tour.readCode ? tour.readCode : '', false)}
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='btns-row-view-dollhouse'>
            {master ? (
              <div className='account-3d-container'>
                <button id='connectGoToStudio' className='connect-goto-studio-dollhouse' onClick={() => goTo3DEditor(tour.readCode)}>
                  <div className='go-to-3d-icon'></div>
                  <Trans id='Go to 3d Editor' />
                </button>
              </div>
            ) : (
              <div className='account-3d-container'>
                <button
                  id='connectGoToStudio'
                  className='connect-goto-studio-dollhouse'
                  onClick={() => {
                    if (connectToUserGoToStudio) {
                      ReportCustomEvent(EnumCategory.ConnectToTour, EnumEvent.ConnectToTourButton);
                      connectToUserGoToStudio(tour.userId, tour.tourId ? tour.tourId : '');
                    }
                  }}
                >
                  <div className='go-to-studio-icon'></div>
                  <Trans id='Go To Studio' />
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default TourRowsDollhouse;
