import { ITours } from "../../../api-helper/interface/interfaces";

export interface IPropsTourCards {
	page?: number;
	keyword?: string;
	deleteArchived?: (tourId: string) => void;
	tours?: ITours[];
	toursRef: any;
	showModal: (modalContent: IModalContent) => void;
	archiveTours?: (id: string, archive: boolean) => void;
	restoreTours?: (id: string) => void;
	duplicateATour?: (tour: string) => void;
	type?: string;
	orderOptionName?: string;
	orderOption?: string;
	rootFeatures?: boolean;
	connectToUserGoToStudio?: (userId: string, tourId: string) => void;
	downloadAllPanoramas?: (tourId: string, tourName: string) => void;
	dollHouseFeatures?: boolean;
	showDollhouseTab?: boolean;
	showOrderDollhouse?: boolean;
	master?: boolean;
	orderDollhouse?: (tourId: string) => void;
	changeDollhouseStatus?: (tourId: string, status: EnumDollhouseStatus) => void;
	createDirectLiveSharing?: (tourUrl: string, tourName: string) => void;
	apiNoPayment?: boolean;
	downloadFloorplans?: (tourId: string, readCode: string) => void;
}

export interface IModal {
	[name: string]: string;
}

export interface IModalContent {
	id: string;
	title?: string;
	tour?: ITours;
	status?: EnumDollhouseStatus;
	tourId?: string;
	tourName?: string;
	userEmail?: string;
	newStatus?: EnumDollhouseStatus;
	backUrl?: string;
	editFloorplan?: boolean;
	panoramaCount?: number;
	apiNoPayment?: boolean;
}


export enum EnumDollhouseStatus {
	Pending = 0,
	Active = 1,
	PendingValidation = 2,
	ValidationRejected = 3,
	Validated = 4,
	Deleted = -1,
	FloorplanSent = 5
}