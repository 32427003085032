import React from 'react';
import emptyStateTours from '../../../images/empty-states-illustrations/empty-tour-state.svg';
import firstTourState from '../../../images/empty-states-illustrations/first-tour-state.svg';
import tripodAnimation from '../../../images/empty-states-illustrations/illustration-tripod.svg';
import './empty-tours.scss';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/react';
import { EnumTabsState } from './dashboard';

interface Props {
    itHasTours: boolean;
    liveTourTab?: EnumTabsState;
}

const EmptyTours = (props: Props) => {
    return (
        <div>
            {props.itHasTours ? (
                <div id='empty-tours-component' className='empty-search-container'>
                    <img src={emptyStateTours} className='we-cannot-find' alt='empty' />
                    <div className='no-tours-container'>
                        <h2 className='title-tours'>
                            <Trans id='We cannot find this virtual tour!' />
                        </h2>
                        <p className='text-tours'>
                            <Trans id='Refine your search from the search tab' /> !
                        </p>
                    </div>
                </div>
            ) : (
                <>
                    {props.liveTourTab === EnumTabsState.LiveTours ? (
                        <div id='empty-tours-component' className='empty-tours-container'>
                            <img src={firstTourState} className='new-tours' alt='empty' />
                            <div className='first-tour-container'>
                                <h2 className='title-first-tours'>
                                    <Trans id='Create your first virtual tour!' />
                                </h2>
                                <p className='text-first-tours'>
                                    <Trans id="Let's get you started with Vieweet right away! only few minutes and your virtual tour will be ready." />
                                </p>
                                <p className='text-start'>
                                    <Trans id="Click the button below to start creating a virtual tour. Don't know how, view the tutorial!" />
                                </p>
                                <div className='container-button'>
                                    <button className='font-lato-generic btn-empty-create-tour button-hover'>
                                        <Link style={{ textDecoration: 'none' }} to='/create-tour'>
                                            <Trans id='Create a tour' />
                                        </Link>
                                    </button>
                                    <a className='example-tour' href='https://onboarding.vieweet.com/' target='_blank' rel='noreferrer'>
                                        <button className='font-lato-generic btn-view-tutorial button-hover'>
                                            <Trans id='View tutorial' />
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : props.liveTourTab === EnumTabsState.UsersDeletedTours ? (
                        <div id='empty-tours-component' className='empty-tours-container'>
                            <img src={tripodAnimation} className='new-tours' alt='empty' />
                            <div className='first-tour-container-users'>
                                <h2 className='title-first-tours'>
                                    <Trans id='No Deleted Tours' />
                                </h2>
                                <p className='text-start'>
                                    <Trans id="Your users haven't deleted any of their tours" />
                                </p>
                            </div>
                        </div>
                    ) : props.liveTourTab === EnumTabsState.UsersArchivedTours ? (
                        <div id='empty-tours-component' className='empty-tours-container'>
                            <img src={tripodAnimation} className='new-tours' alt='empty' />
                            <div className='first-tour-container-users'>
                                <h2 className='title-first-tours'>
                                    <Trans id='No Archived Tours' />
                                </h2>
                                <p className='text-start'>
                                    <Trans id="Your users haven't archived any of their tours" />
                                </p>
                            </div>
                        </div>
                    ) : window.location.pathname.includes('users-tours') ? (
                        <div id='empty-tours-component' className='empty-tours-container'>
                            <img src={tripodAnimation} className='new-tours' alt='empty' />
                            <div className='first-tour-container-users'>
                                <h2 className='title-first-tours'>
                                    <Trans id='No Tours yet!' />
                                </h2>
                                <p className='text-start'>
                                    <Trans id="Your users haven't started creating virtual tour yet - encourage them to get started today!" />
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div id='empty-tours-component' className='empty-tours-container'>
                            <img src={firstTourState} className='new-tours' alt='empty' />
                            <div className='first-tour-container'>
                                <h2 className='title-first-tours'>
                                    <Trans id='Order your first dollhouse!' />
                                </h2>
                                <p className='text-first-tours'>
                                    <Trans id='Enhances the overall visual experience with the 3D model of your properties.' />
                                </p>
                                <p className='text-start'>
                                    <Trans
                                        id='The customer can navigate from 360° to the 3D model in 1 click.
                        Go to Your Tours tab and click Order Dollhouse in any property of your choice.'
                                    />
                                </p>
                                <div className='container-button'>
                                    <a
                                        className='example-tour'
                                        href='https://3dtour.vieweet.com/?tour=GQGDEXF'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <button className='font-lato-generic btn-empty-create-tour button-hover'>
                                            <Trans id='See an example' />
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default EmptyTours;
