import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { IForm } from './interface';
import { Tabs, TabHooks, PanelHooks } from '../../utils/tabs/tabs';
import { usePasswordValidation } from '../../utils/custom-hooks/usePasswordValidation';
import { isURLValid } from '../../editing-viewer/viewer-canvas-utils/viewer-canvas-helper';
import { ValidationUtils } from '../../utils/validations/validations';
import { updateUser, updateSingleInput, getUser, deleteOwnAccount } from '../../../api-helper/api-user';
import NavSidebar from '../../utils/nav-sidebar/nav-sidebar';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import cross from '../../../images/icons/pwd-check-cross.svg';
import tick from '../../../images/icons/pwd-check-tick.svg';
import showPasswordIcon from '../../../images/icons/password-hidden.svg';
import hidePasswordIcon from '../../../images/icons/password-shown.svg';
import deleteButtonIcon from '../../../images/icons/delete-button.svg';
import selectArrow from '../../../images/dashboard-icons/select-arrow.svg';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import Footer from '../../utils/footer/footer';
import './profile.scss';
import { ReportPageView, ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent, EnumPagesTitles } from '../../../google-analytics-track-list';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';
import DeleteProfileModal from './delete-profile-modal';
import { useAuth } from '../../../auth-routes/auth-context';
import { handleError } from '../../../api-helper/api-error-handling';
import { useHistory } from 'react-router';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import DropDownCountries from '../../utils/dropdown-front/drop-down-countries';

interface IPassword {
  password: string;
}
interface IUserData {
  fullName: string;
  email: string;
  internetEmail?: string;
  phoneNumber?: string;
  countryId?: string;
  countryName?: string;
  userCompanyVat?: string;
  userCompany?: string;
  userWebsite?: string;
  userAddress?: string;
  propstackApiKey?: string;
}

export interface ISelectedCountry {
  country: string;
  latitude: string;
  longitude: string;
  name: string;
}

const Profile: React.FC = () => {
  const history = useHistory();
  // User config context
  const config = useConfig();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [countries, setCountries] = useState<any>();
  const [responseUserData, setResponseUserData] = useState<any>();
  const [detailsButtonDisable, setDetailsButtonDisable] = useState<boolean | undefined>(false);
  const [passwordButtonDisable, setPasswordButtonDisable] = useState<boolean>(false);
  const [password, setPassword] = useState<IPassword>({
    password: '',
  });
  const [passwordChange, setPasswordChange] = useState<IForm>({
    current: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [validLength, hasNumber, upperCase, specialChar, match] = usePasswordValidation({
    firstPassword: passwordChange.newPassword,
    secondPassword: passwordChange.confirmNewPassword,
    requiredLength: 8,
    numberValidation: true,
    lengthValidation: true,
    uppercaseValidation: true,
    specialCharacterValidation: true,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserData>({
    fullName: '',
    email: '',
  });
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showPasswordRequirements, setShowPasswordRequirements] = useState<boolean>(false);
  const { handleLogoutState } = useAuth();
  const [tabIndex, setTabIndex] = useState<number>();
  const [loadedInfo, setLoadedInfo] = useState<boolean>(false);
  const [klComponents, setKlComponents] = useState(false);
  const [propstackApiKey, setPropstackApiKey] = useState<string>();

  const panelHooksStyle = {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'visible',
  };

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  const getUserData = async () => {
    try {
      const user = await getUser();
      if (user.options) {
        const apiKey = user?.options.find(o => o.name === 'PROPSTACK_API_KEY');
        if (apiKey && apiKey.value !== '') {
          setPropstackApiKey(apiKey.value);
        }
      }
      setUserData(user);
      setLoaded(true);
      setLoadedInfo(true);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  useEffect(() => {
    //Google report page
    ReportPageView(EnumPagesTitles.Profile);
  }, []);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (config && config.countries) {
      setCountries(config.countries);
    }
    if (config && config.modules) {
      for (const m of config.modules) {
        const mKey = Object.keys(m);
        const mValue = Object.values(m);
        if (mKey[0] === 'klComponents' && mValue[0]) {
          setKlComponents(true);
        }
      }
    }
  }, [config]);

  useEffect(() => {
    if (responseUserData) {
      setUserData(responseUserData);
    }
  }, [responseUserData]);

  useEffect(() => {
    disableDetailsButton(userData);
  }, [userData]);

  useEffect(() => {
    setPasswordButtonDisable(!checkPasswordParameters(validLength, hasNumber, upperCase, specialChar, match) ? true : false);
  }, [validLength, hasNumber, upperCase, specialChar, match]);

  const disableDetailsButton = (userData: IUserData) => {
    if (!userData.fullName && !userData.email) {
      setDetailsButtonDisable(true);
    }
    if (userData.fullName && userData.email) {
      validatePhoneAndOrEmail(userData);
    } else {
      setDetailsButtonDisable(true);
    }
  };

  const validatePhoneAndOrEmail = (userData: IUserData) => {
    if (!userData.userWebsite && !userData.phoneNumber) {
      setDetailsButtonDisable(
        !ValidationUtils.isValidFullName(userData.fullName) || !ValidationUtils.isEmail(userData.email) ? true : false,
      );
    } else if (userData.userWebsite && !userData.phoneNumber) {
      isURLValid(userData.userWebsite) ? setDetailsButtonDisable(false) : setDetailsButtonDisable(true);
    } else if (!userData.userWebsite && userData.phoneNumber) {
      ValidationUtils.isValidPhone(userData.phoneNumber, userData.countryId || '')
        ? setDetailsButtonDisable(false)
        : setDetailsButtonDisable(true);
    } else if (userData.userWebsite && userData.phoneNumber) {
      setDetailsButtonDisable(
        !ValidationUtils.isValidFullName(userData.fullName) ||
          !ValidationUtils.isEmail(userData.email) ||
          !ValidationUtils.isValidPhone(userData.phoneNumber, userData.countryId || '') ||
          !isURLValid(userData.userWebsite)
          ? true
          : false,
      );
    }
  };

  const checkPasswordParameters = (
    validLength: boolean,
    hasNumber: boolean,
    upperCase: boolean,
    hasSpecialChar: boolean,
    match: boolean,
  ) => {
    const isValid = (currentValue: boolean) => currentValue === true;
    const params = [validLength, hasNumber, upperCase, hasSpecialChar, match];
    return params.every(isValid);
  };

  const handleInputValue = async (selectedValue: ISelectedCountry[]) => {
    if (selectedValue && selectedValue.length) {
      setUserData({ ...userData, countryId: selectedValue[0].country, countryName: selectedValue[0].name });
    } else {
      setUserData({ ...userData, countryId: '', countryName: '' });
    }
  };

  const handlerCollectUserDetailsData = async () => {
    try {
      let temp = { ...userData };
      if (klComponents) {
        temp.propstackApiKey = propstackApiKey;
      }
      const updatedUser = await updateUser(temp);
      setResponseUserData(updatedUser);
      const message = linguiTranslateToString('The changes have been saved');
      toggleNotification(EnumNotificationType.Success, message);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const handlerCollectUserPasswordData = async () => {
    try {
      const updatedPassword = await updateSingleInput(password);
      setPassword(updatedPassword);
      const message = linguiTranslateToString('The changes have been saved');
      toggleNotification(EnumNotificationType.Success, message);
      setPasswordChange({
        current: '',
        newPassword: '',
        confirmNewPassword: '',
      });
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordChange({
      ...passwordChange,
      [e.target.name]: e.target.value,
    });
    setPassword({ ...password, password: passwordChange.newPassword });
  };

  const deleteAccountPermanently = async () => {
    setLoaded(false);
    try {
      await deleteOwnAccount();
      const message = linguiTranslateToString('Account deleted you will now be logged out');
      toggleNotification(EnumNotificationType.Success, message);
      setLoaded(true);
      handleLogoutState();
      setShowDeleteModal(false);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      setShowDeleteModal(false);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  useEffect(() => {
    // Sets tab on load
    const urlPath = window.location.pathname;
    onChangeUrl(urlPath);
  }, []);

  useEffect(() => {
    // Changes tab ONLY when back button is pressed
    window.onpopstate = (e: any) => {
      onChangeUrl(e.currentTarget.location.pathname);
    };
  }, []);

  // Only called when tab in manually changed
  const changeOfTab = (index: number) => {
    setTabIndex(index);
    if (index === 0 && !window.location.pathname.includes('profile')) {
      history.push('/profile');
    }
    if (index === 1 && !window.location.pathname.includes('security-and-account')) {
      history.push('/security-and-account');
    }
  };

  const onChangeUrl = (urlPath: string) => {
    if (urlPath.includes('profile')) {
      setTabIndex(0);
    } else if (urlPath.includes('security-and-account')) {
      setTabIndex(1);
    }
  };

  const pwdShouldContainSection = (
    <div className='pwd-should-contain-section-profile'>
      <div className='pwd-should-contain-title-profile'>
        <Trans id='Your password should be' />
      </div>
      <div className='pwd-should-contain-profile'>
        <img className='pwd-check-status-profile' src={!validLength ? cross : tick} alt='Icon tick or cross' />
        <span>
          <Trans id='At least 8 characters' />
        </span>
      </div>
      <div className='pwd-should-contain-profile'>
        <img className='pwd-check-status-profile' src={!upperCase ? cross : tick} alt='Icon tick or cross' />
        <span>
          <Trans id='A capital letter' />
        </span>
      </div>
      <div className='pwd-should-contain-profile'>
        <img className='pwd-check-status-profile' src={!hasNumber ? cross : tick} alt='Icon tick or cross' />
        <span>
          <Trans id='A number' />
        </span>
      </div>
      <div className='pwd-should-contain-profile'>
        <img className='pwd-check-status-profile' src={!specialChar ? cross : tick} alt='Icon tick or cross' />
        <span>
          <Trans id='Contain a special character' /> e.g. [!@£$%^&*()]
        </span>
      </div>
      <div className='pwd-should-contain-profile'>
        <img
          className='pwd-check-status-profile'
          src={passwordChange.newPassword !== '' && passwordChange.newPassword === passwordChange.confirmNewPassword ? tick : cross}
          alt='Icon tick or cross'
        />
        <span>
          <Trans id='Match' />
        </span>
      </div>
    </div>
  );

  const getCountryNameFromId = () => {
    if (countries) {
      const userCountryId = userData.countryId || 'GB';
      const userCountryData = countries?.find((country: ISelectedCountry) => country.country === userCountryId);
      return userCountryData.name;
    } else {
      return '';
    }
  };

  return (
    <>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
        />
      )}
      <div className='dashboard-layout'>
        {!loaded && <LoadingSpinner />}
        <NavSidebar />
        <div className='dashboard'>
          <div className='content-wrapper'>
            <Tabs changeOfTab={changeOfTab} tabIndex={tabIndex}>
              <div className='display-flex'>
                <div className='profile-header-tabs-container'>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      //Report Custom Category and Event
                      ReportCustomEvent(EnumCategory.Header, EnumEvent.ViewYourDetails);
                    }}
                  >
                    <TabHooks>
                      <div className={tabIndex === 0 ? 'btn-dashboard-active' : 'btn-dashboard-inactive'}>
                        <div className='btn-dashboard-text'>
                          <Trans id='Your details' />
                        </div>
                      </div>
                    </TabHooks>
                  </div>
                  <div
                    id='security-panel'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      //Report Custom Category and Event
                      ReportCustomEvent(EnumCategory.Header, EnumEvent.ViewSecuritySettings);
                    }}
                  >
                    <TabHooks>
                      <div className={tabIndex === 1 ? 'btn-dashboard-active' : 'btn-dashboard-inactive'}>
                        <div className='btn-dashboard-text'>
                          <Trans id='Security and account' />
                        </div>
                      </div>
                    </TabHooks>
                  </div>
                </div>
              </div>
              {loadedInfo ? (
                <div className='profile-information-container'>
                  <div className='profile-information-layout'>
                    <PanelHooks inLineStyle={panelHooksStyle}>
                      <>
                        <div className='profile-panelhook-container'>
                          <div className='profile-blocks'>
                            <div className='profile-field-container'>
                              <Trans
                                id='Your Name'
                                render={({ translation }) => (
                                  <label htmlFor='input-name' className='profile-field-text'>
                                    {translation}
                                  </label>
                                )}
                              />
                              <input
                                id='input-name'
                                type='text'
                                value={userData.fullName}
                                className={`general-field-input-text ${
                                  ValidationUtils.isValidFullName(userData.fullName) ? 'input-valid-black' : 'input-invalid'
                                }`}
                                placeholder=' '
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setUserData(() => ({ ...userData, fullName: e.target.value }))
                                }
                              />
                            </div>
                            <div className='profile-field-container'>
                              <Trans
                                id='Email Address'
                                render={({ translation }) => (
                                  <label
                                    htmlFor='input-email'
                                    className={`profile-field-text ${
                                      ValidationUtils.isEmail(userData.email) ? 'label-valid ' : 'label-invalid '
                                    }`}
                                  >
                                    {translation}
                                  </label>
                                )}
                              />
                              <input
                                id='input-email'
                                type='text'
                                value={userData.email}
                                className={`general-field-input-text ${
                                  ValidationUtils.isEmail(userData.email) ? 'input-valid-black' : 'input-invalid'
                                }`}
                                placeholder=' '
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setUserData(() => ({ ...userData, email: e.target.value }))
                                }
                              />
                            </div>
                            <div className='profile-field-container'>
                              <Trans
                                id='Communications Email Address'
                                render={({ translation }) => (
                                  <label
                                    htmlFor='communications-input-email'
                                    className={`profile-field-text ${
                                      ValidationUtils.isEmail(userData?.internetEmail || '') ? 'label-valid ' : 'label-invalid'
                                    }`}
                                  >
                                    {translation}
                                  </label>
                                )}
                              />
                              <input
                                id='communications-input-email'
                                type='text'
                                value={userData.internetEmail || ''}
                                className={`general-field-input-text ${
                                  ValidationUtils.isEmail(userData?.internetEmail || '') ? 'input-valid-black' : 'input-invalid'
                                }`}
                                placeholder=' '
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setUserData(() => ({ ...userData, internetEmail: e.target.value }))
                                }
                              />
                            </div>
                          </div>
                          <div className='profile-blocks'>
                            <div className='profile-field-container'>
                              <Trans
                                id='Country'
                                render={({ translation }) => <label className='profile-field-text'>{translation}</label>}
                              />
                              <div className={'profile-countries-container'}>
                                <div id='countries-input'>
                                  <DropDownCountries
                                    selectedProps={[getCountryNameFromId()]}
                                    dataset={countries ? countries : []}
                                    allowNew={false}
                                    isDisabled={false}
                                    labelKey={'name'}
                                    handleSelectedItem={handleInputValue}
                                    IsResultHighlightOnly={false}
                                    placeHolderValue=''
                                  />
                                </div>
                                <img className='arrow-country-dropdown' src={selectArrow} alt='Drop down menu arrow country' />
                              </div>
                            </div>
                            <div className='profile-field-container'>
                              <Trans
                                id='Company Name (optional)'
                                render={({ translation }) => (
                                  <label
                                    htmlFor='input-name-company'
                                    className={` ${
                                      userData.userCompany === ''
                                        ? 'profile-field-text'
                                        : userData.userCompany && userData.userCompany.length < 2
                                        ? 'label-invalid'
                                        : 'profile-field-text'
                                    }`}
                                  >
                                    {translation}
                                  </label>
                                )}
                              />
                              <input
                                id='input-name-company'
                                type='text'
                                value={userData && userData.userCompany ? userData.userCompany : ''}
                                className={`general-field-input-text ${
                                  userData.userCompany === ''
                                    ? 'input-default'
                                    : userData.userCompany && userData.userCompany.length < 2
                                    ? 'input-invalid'
                                    : 'input-valid-black'
                                }`}
                                placeholder=' '
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setUserData(() => ({ ...userData, userCompany: e.target.value }))
                                }
                              />
                            </div>

                            <div className='profile-field-container'>
                              <Trans
                                id='Company Address (optional)'
                                render={({ translation }) => (
                                  <label
                                    htmlFor='input-adress'
                                    className={` ${
                                      userData.userAddress === ''
                                        ? 'profile-field-text'
                                        : userData.userAddress && userData.userAddress.length < 2
                                        ? 'label-invalid'
                                        : 'profile-field-text '
                                    }`}
                                  >
                                    {translation}
                                  </label>
                                )}
                              />
                              <input
                                id='input-adress'
                                type='text'
                                value={userData && userData.userAddress ? userData.userAddress : ''}
                                className={`general-field-input-text ${
                                  userData.userAddress === ''
                                    ? 'input-default'
                                    : userData.userAddress && userData.userAddress.length < 2
                                    ? 'input-invalid'
                                    : 'input-valid-black'
                                }`}
                                placeholder=' '
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setUserData(() => ({ ...userData, userAddress: e.target.value }))
                                }
                              />
                            </div>
                          </div>
                          <div className='profile-blocks'>
                            <div className='profile-field-container'>
                              <Trans
                                id='Company Website (optional)'
                                render={({ translation }) => (
                                  <label
                                    htmlFor='input-site'
                                    className={` ${
                                      userData.userWebsite === ''
                                        ? 'profile-field-text'
                                        : isURLValid(userData.userWebsite || '')
                                        ? 'label-valid '
                                        : 'label-invalid'
                                    }`}
                                  >
                                    {translation}
                                  </label>
                                )}
                              />
                              <input
                                id='input-site'
                                type='text'
                                value={userData && userData.userWebsite ? userData.userWebsite : ''}
                                className={`general-field-input-text ${
                                  userData.userWebsite === ''
                                    ? 'input-default'
                                    : isURLValid(userData.userWebsite || '')
                                    ? 'input-valid-black'
                                    : 'input-invalid'
                                }`}
                                placeholder=' '
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setUserData(() => ({ ...userData, userWebsite: e.target.value }))
                                }
                              />
                            </div>
                            <div className='profile-field-container'>
                              <Trans
                                id='Phone Number (optional)'
                                render={({ translation }) => (
                                  <label
                                    htmlFor='input-phone'
                                    className={` ${
                                      userData.phoneNumber === ''
                                        ? 'profile-field-text'
                                        : ValidationUtils.isValidPhone(userData.phoneNumber || '', userData.countryId || '')
                                        ? 'label-valid '
                                        : 'label-invalid'
                                    }`}
                                  >
                                    {translation}
                                  </label>
                                )}
                              />
                              <input
                                id='input-phone'
                                type='tel'
                                value={userData && userData.phoneNumber?.length ? userData.phoneNumber : ''}
                                className={`general-field-input-text ${
                                  userData.phoneNumber === ''
                                    ? 'input-default'
                                    : ValidationUtils.isValidPhone(userData.phoneNumber || '', userData.countryId || '')
                                    ? 'input-valid-black'
                                    : 'input-invalid'
                                }`}
                                placeholder=' '
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setUserData(() => ({ ...userData, phoneNumber: e.target.value }))
                                }
                              />
                            </div>
                            <div className='profile-field-container'>
                              <Trans
                                id='VAT Number (optional)'
                                render={({ translation }) => (
                                  <label htmlFor='input-vat' className='profile-field-text-vat'>
                                    {translation}
                                  </label>
                                )}
                              />
                              <input
                                id='input-vat'
                                type='text'
                                value={userData && userData.userCompanyVat ? userData.userCompanyVat : ''}
                                className={`general-field-input-text ${
                                  userData.userCompanyVat !== '' ? 'input-valid-black' : 'input-default'
                                }`}
                                placeholder=' '
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setUserData(() => ({ ...userData, userCompanyVat: e.target.value }))
                                }
                              />
                            </div>
                          </div>
                          {klComponents && (
                            <div className='profile-blocks'>
                              <div className='profile-field-container'>
                                <Trans
                                  id='Propstack Api Key'
                                  render={({ translation }) => (
                                    <label htmlFor='input-site' className={'profile-field-text-vat'}>
                                      {translation}
                                    </label>
                                  )}
                                />
                                <input
                                  id='input-site'
                                  type='text'
                                  value={propstackApiKey || ''}
                                  className={`general-field-input-text ${propstackApiKey !== '' ? 'input-valid-black' : 'input-default'}`}
                                  placeholder=' '
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPropstackApiKey(e.target.value)}
                                />
                              </div>
                              <div className='profile-field-container'></div>
                              <div className='profile-field-container'></div>
                            </div>
                          )}
                          <div className='profile-save-information-button-container'>
                            <button
                              className={`profile-save-information-button ${
                                detailsButtonDisable ? 'button-inactive' : 'button-active button-hover'
                              }`}
                              onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                handlerCollectUserDetailsData();
                                ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.SaveChanges);
                              }}
                              disabled={detailsButtonDisable ? true : false}
                            >
                              <Trans id='Save changes' />
                            </button>
                          </div>
                        </div>
                      </>
                    </PanelHooks>
                    <PanelHooks inLineStyle={panelHooksStyle}>
                      <>
                        <div className='profile-panelhook-container'>
                          <div className='security-information'>
                            <div className='security-fields'>
                              <div className='profile-blocks'>
                                <div className='profile-field-container' id='new-password-field-container'>
                                  <Trans
                                    id='Your new password'
                                    render={({ translation }) => (
                                      <label
                                        htmlFor='new-password'
                                        className={` ${
                                          passwordChange.newPassword === ''
                                            ? 'profile-field-text'
                                            : match
                                            ? 'label-valid '
                                            : 'label-invalid'
                                        }`}
                                      >
                                        {translation}
                                      </label>
                                    )}
                                  />
                                  <input
                                    autoComplete='off'
                                    id='new-password'
                                    name='newPassword'
                                    type={showPassword ? 'text' : 'password'}
                                    className={`general-field-input-text ${
                                      passwordChange.newPassword === '' ? '' : !match ? 'input-invalid' : 'input-valid-black'
                                    }`}
                                    placeholder=' '
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                      handlePasswordChange(e);
                                    }}
                                    onFocus={() => setShowPasswordRequirements(true)}
                                    onBlur={() => setShowPasswordRequirements(false)}
                                    tabIndex={1}
                                    value={passwordChange.newPassword}
                                  />
                                  <button
                                    type='button'
                                    onClick={(e: React.MouseEvent): void => {
                                      e.preventDefault();
                                      setShowPassword(!showPassword);
                                    }}
                                    className='profile-show-password-icon'
                                  >
                                    <img src={showPassword ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
                                  </button>
                                </div>
                                <div className='profile-field-container' id='confirm-password-field-container'>
                                  <Trans
                                    id='Confirm new password'
                                    render={({ translation }) => (
                                      <label
                                        htmlFor='confirm-password'
                                        className={` ${
                                          passwordChange.confirmNewPassword === ''
                                            ? 'profile-field-text'
                                            : match
                                            ? 'label-valid '
                                            : 'label-invalid'
                                        }`}
                                      >
                                        {translation}
                                      </label>
                                    )}
                                  />
                                  <input
                                    id='confirm-password'
                                    autoComplete='off'
                                    name='confirmNewPassword'
                                    type={showPasswordConfirmation ? 'text' : 'password'}
                                    className={`general-field-input-text ${
                                      passwordChange.confirmNewPassword === '' ? '' : !match ? 'input-invalid' : 'input-valid-black'
                                    }`}
                                    placeholder=' '
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                      handlePasswordChange(e);
                                    }}
                                    onFocus={() => setShowPasswordRequirements(true)}
                                    onBlur={() => setShowPasswordRequirements(false)}
                                    tabIndex={2}
                                    value={passwordChange.confirmNewPassword}
                                  />
                                  <button
                                    type='button'
                                    onClick={(e: React.MouseEvent): void => {
                                      e.preventDefault();
                                      setShowPasswordConfirmation(!showPasswordConfirmation);
                                    }}
                                    className='profile-show-password-icon'
                                  >
                                    <img src={showPasswordConfirmation ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
                                  </button>
                                </div>
                              </div>
                              <div className='profile-blocks'>
                                <div className='profile-field-container' id='confirm-password-field-container'>
                                  <Trans
                                    id='Delete my account'
                                    render={({ translation }) => <label className='profile-field-text'>{translation}</label>}
                                  />
                                  <div className='new-delete-account-btn'>
                                    <div>
                                      <button onClick={() => setShowDeleteModal(true)} className='btn-remove-default'>
                                        <img src={deleteButtonIcon} alt='delete' />
                                      </button>
                                    </div>
                                    <div style={{ cursor: 'default' }}>
                                      <Trans id='The account and the tours will be deleted. Deleting the account is permanent and cannot be recovered.' />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {showPasswordRequirements && <div className='security-requirements'>{pwdShouldContainSection}</div>}
                          </div>
                          <div className='profile-save-information-button-container' style={{ marginTop: '40px' }}>
                            <button
                              id='security-button'
                              className={`profile-save-information-button ${
                                passwordButtonDisable ? 'button-inactive' : 'button-active button-hover'
                              }`}
                              onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                handlerCollectUserPasswordData();
                                ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.SaveChanges);
                              }}
                              disabled={passwordButtonDisable ? true : false}
                            >
                              <Trans id='Save changes' />
                            </button>
                          </div>
                        </div>
                      </>
                    </PanelHooks>
                  </div>
                </div>
              ) : (
                <LoadingSpinner />
              )}
            </Tabs>
          </div>
          <Footer />
        </div>
      </div>
      {showDeleteModal && (
        <DeleteProfileModal
          email={userData.email}
          setShowDeleteModal={setShowDeleteModal}
          deleteAccountPermanently={deleteAccountPermanently}
        />
      )}
    </>
  );
};

export default Profile;
