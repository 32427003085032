import React, { useEffect, useState } from 'react';
import SearchBar from '../../utils/searchbar/searchbar';
import { AccountNotFoundCard } from './not-found-account-management';
import { Trans } from '@lingui/react';
import CustomSelect from '../../utils/custom-select/custom-select';
import DataTable from '../../utils/data-table/data-table';
import { IChangeSortPagination, IUserAccount } from './account-management-canvas';
import { defineMessage } from '@lingui/macro';
import { IAccountMaster } from '../../../api-helper/interface/interfaces';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import { useHistory } from 'react-router';

interface IProps {
    totalNumAccounts: number;
    keyword: string;
    handlerSetSearchBarValue: (keywordValue: string) => void;
    handlerSearching: (isSearchbarLoaded: boolean, page: number) => void;
    loadedView: boolean;
    goToEditCreateAccount: (account?: IUserAccount) => void;
    totalCount: number;
    goToEditAdmin: (account?: IUserAccount) => void;
    orderOptionName: string;
    showCustomDropDown: boolean;
    toggleCustomDropdown: (option?: boolean) => void;
    handleConnectUser: (account: IUserAccount) => void;
    changeMasterSortByPagination: (option: IChangeSortPagination) => void;
    masterData?: IAccountMaster[];
    userPage: number;
    userPageCount: number;
    handleUserPaginationSearch: (pageSelected: any) => void;
    masterLoadedPage: boolean;
}

const AdminsAccountManagement: React.FC<IProps> = ({
    totalNumAccounts,
    keyword,
    handlerSetSearchBarValue,
    handlerSearching,
    loadedView,
    goToEditCreateAccount,
    goToEditAdmin,
    totalCount,
    orderOptionName,
    showCustomDropDown,
    toggleCustomDropdown,
    handleConnectUser,
    changeMasterSortByPagination,
    masterData,
    userPage,
    masterLoadedPage,
}) => {
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const [itHasAdmins, setItHasAdmins] = useState<boolean>(false);

    const labelsMaster = [
        { tableTitle: <Trans id='Master name' />, value: 'adminName' },
        { tableTitle: <Trans id='Email' />, value: 'email' },
        { tableTitle: <Trans id='Accounts' />, value: 'accounts' },
        { tableTitle: <Trans id='Status' />, value: 'status' },
        { tableTitle: <Trans id='Created' />, value: 'createdAt' },
        { tableTitle: <Trans id='Last Login' />, value: 'lastLogin' },
        { tableTitle: <Trans id='Actions' />, value: '' },
    ];
    useEffect(() => {
        if (masterData && masterData.length) {
            setItHasAdmins(true);
        } else {
            setItHasAdmins(false);
        }
    }, [masterData]);

    useEffect(() => {
        managePaginationSortBy();
    }, []);

    const managePaginationSortBy = () => {
        const pageFromUrl = Number(params.get('page'));
        const sortByUrl = params.get('sortBy');
        const obj: IChangeSortPagination = {};

        if (sortByUrl) {
            obj.sortBy = sortByUrl;
        }
        if (pageFromUrl) {
            obj.page = pageFromUrl - 1 || 0;
        }
        changeMasterSortByPagination(obj);
    };

    const changeSortBy = (sortBy: string) => {
        const pageFromUrl = Number(params.get('page'));
        if (pageFromUrl) {
            history.push(`/admin-management?sortBy=${sortBy}&page=${userPage + 1}`);
        } else {
            history.push(`/admin-management?sortBy=${sortBy}`);
        }

        changeMasterSortByPagination({ sortBy: sortBy });
    };

    return (
        <>
            <div className={`${itHasAdmins ? 'am-header' : !itHasAdmins && totalNumAccounts !== 0 ? 'am-header' : 'am-header-no-content'}`}>
                {itHasAdmins ? (
                    <Trans
                        id={'Search for an account'}
                        render={({ translation }) => (
                            <SearchBar
                                placeholder={String(translation)}
                                searchBarValue={keyword}
                                handlerSetSearchBarValue={handlerSetSearchBarValue}
                                handlerSearching={handlerSearching}
                            />
                        )}
                    />
                ) : !itHasAdmins && totalNumAccounts !== 0 ? (
                    <Trans
                        id={'Search for an account'}
                        render={({ translation }) => (
                            <SearchBar
                                placeholder={String(translation)}
                                searchBarValue={keyword}
                                handlerSetSearchBarValue={handlerSetSearchBarValue}
                                handlerSearching={handlerSearching}
                            />
                        )}
                    />
                ) : null}
            </div>
            {masterLoadedPage && (
                <AccountNotFoundCard
                    itHasData={itHasAdmins}
                    loadedView={loadedView}
                    totalNumAccounts={totalNumAccounts}
                    goToEditCreateAccount={goToEditCreateAccount}
                />
            )}
            {itHasAdmins && (
                <>
                    <div className='sort-by-num-accounts-container'>
                        <Trans
                            id='Master accounts'
                            render={({ translation }) => (
                                <p>
                                    <b>{totalCount || 0}</b> {translation}
                                </p>
                            )}
                        />

                        <div className='am-sort-by-container'>
                            <label className='am-sort-by-label'>
                                <Trans id='Sort by' />
                            </label>

                            <CustomSelect
                                value={orderOptionName}
                                options={[
                                    defineMessage({ message: 'Alphabetical order' }),
                                    defineMessage({ message: 'Newest - Oldest' }),
                                    defineMessage({ message: 'Oldest - Newest' }),
                                    defineMessage({ message: 'Most Accounts' }),
                                ]}
                                changeDropdownValue={changeSortBy}
                                showCustomDropDown={showCustomDropDown}
                                setShowCustomDropDown={toggleCustomDropdown}
                                customClass={`am-custom-select ${showCustomDropDown ? 'am-custom-select-focus' : ''}`}
                                customArrow='am-custom-arrow'
                            />
                        </div>
                    </div>
                    <div className='am-data-table'>
                        {masterLoadedPage ? (
                            <>
                                <DataTable
                                    labelContainerStyles={'master-label-container'}
                                    dataContainerStyles={'master-data-container'}
                                    accounts={masterData}
                                    labels={labelsMaster}
                                    firstAction={goToEditAdmin}
                                    secondAction={handleConnectUser}
                                    isLoaded={true}
                                />
                            </>
                        ) : (
                            <LoadingSpinner loaded={false} />
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default AdminsAccountManagement;
