import React from 'react';
import EmptyTours from './empty-tours';
import TourRows from '../../utils/tour-views/tour-rows';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import Pagination from '../../utils/pagination/pagination';
import { EnumTabsState, IPagination } from './dashboard';
import { ITours } from '../../../api-helper/interface/interfaces';
import { EnumDollhouseStatus, IModalContent } from '../../utils/tour-views/interface';
import TourRowsDollhouse from '../../utils/tour-views/tour-rows-dollhouse';
import TourRowsUsers from '../../utils/tour-views/tour-rows-users';

interface IProps {
    keyword: string;
    orderOptionName: string;
    orderOption: string;
    loaded: boolean;
    error: boolean;
    tours?: ITours[];
    toursRef: React.MutableRefObject<HTMLDivElement[]>;
    archiveTours: (id: string, archive: boolean) => void;
    duplicateATour: (tourId: string) => void;
    showModal: (modalContent: IModalContent) => void;
    handlePaginationSearch: (selected: IPagination) => void;
    pageCount: number;
    page: number;
    itHasTours: boolean;
    isTooltipActive?: boolean;
    activeTooltipHandler?: () => void;
    rootFeatures?: boolean;
    connectToUserGoToStudio?: (userId: string, tourId: string) => void;
    downloadAllPanoramas: (tourId: string, tourName: string) => void;
    dollHouseFeatures?: boolean;
    showDollhouseTab?: boolean;
    showOrderDollhouse?: boolean;
    master?: boolean;
    changeDollhouseStatus?: (tourId: string, status: EnumDollhouseStatus) => void;
    liveTourTab?: EnumTabsState;
    changePaginationPage: (page: IPagination) => void;
    apiNoPayment?: boolean;
    downloadFloorplans: (tourId: string, readCode: string) => void;
}

const ToursList: React.FC<IProps> = ({
    keyword,
    orderOptionName,
    orderOption,
    loaded,
    error,
    tours,
    toursRef,
    archiveTours,
    duplicateATour,
    showModal,
    pageCount,
    page,
    itHasTours,
    rootFeatures,
    connectToUserGoToStudio,
    downloadAllPanoramas,
    dollHouseFeatures,
    showDollhouseTab,
    showOrderDollhouse,
    master,
    changeDollhouseStatus,
    liveTourTab,
    changePaginationPage,
    apiNoPayment,
    downloadFloorplans,
}) => {
    if (!loaded && !error) {
        return <LoadingSpinner loaded={loaded} />;
    } else if (loaded && tours && tours.length < 1) {
        return <EmptyTours itHasTours={itHasTours} liveTourTab={liveTourTab} />;
    } else {
        return (
            <React.Fragment>
                <>
                    {rootFeatures ? (
                        <TourRowsUsers
                            page={page}
                            keyword={keyword}
                            tours={tours}
                            toursRef={toursRef}
                            showModal={showModal}
                            archiveTours={archiveTours}
                            duplicateATour={duplicateATour}
                            orderOptionName={orderOptionName}
                            orderOption={orderOption}
                            connectToUserGoToStudio={connectToUserGoToStudio ? connectToUserGoToStudio : undefined}
                            downloadAllPanoramas={downloadAllPanoramas}
                            showDollhouseTab={showDollhouseTab}
                            apiNoPayment={apiNoPayment || false}
                            downloadFloorplans={downloadFloorplans}
                        />
                    ) : dollHouseFeatures ? (
                        <TourRowsDollhouse
                            tours={tours}
                            toursRef={toursRef}
                            showModal={showModal}
                            archiveTours={archiveTours}
                            duplicateATour={duplicateATour}
                            orderOptionName={orderOptionName}
                            orderOption={orderOption}
                            connectToUserGoToStudio={connectToUserGoToStudio ? connectToUserGoToStudio : undefined}
                            downloadAllPanoramas={downloadAllPanoramas}
                            master={master}
                            changeDollhouseStatus={changeDollhouseStatus}
                            apiNoPayment={apiNoPayment || false}
                        />
                    ) : (
                        <TourRows
                            page={page}
                            keyword={keyword}
                            tours={tours}
                            toursRef={toursRef}
                            showModal={showModal}
                            archiveTours={archiveTours}
                            duplicateATour={duplicateATour}
                            orderOptionName={orderOptionName}
                            orderOption={orderOption}
                            downloadAllPanoramas={downloadAllPanoramas}
                            showDollhouseTab={showDollhouseTab}
                            showOrderDollhouse={showOrderDollhouse}
                            apiNoPayment={apiNoPayment || false}
                            downloadFloorplans={downloadFloorplans}
                        />
                    )}
                </>
                {tours && (
                    <div className='pagination-container'>
                        <Pagination page={page} pageCount={pageCount} handlePaginationSearch={changePaginationPage} />
                    </div>
                )}
            </React.Fragment>
        );
    }
};

export default ToursList;
