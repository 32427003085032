import { Trans } from '@lingui/react';
import React from 'react';
import { handleError } from '../../../api-helper/api-error-handling';
import { getRestoreTour } from '../../../api-helper/api-tours';
import { useAuth } from '../../../auth-routes/auth-context';
import { EnumNotificationType } from '../../utils/notifications/notification';
import TourRows from '../../utils/tour-views/tour-rows';
import { EnumTabsState } from '../dashboard/dashboard';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';

interface IProps {
    toursRef: any;
    archivedTours: any;
    deletedTours: any;
    toggleNotification: (type: EnumNotificationType, message?: string) => void;
    scrollToTop: () => void;
    deleteArchived: (tourId: string) => void;
    showModal: (modalContent: any) => void;
    getToursAsync: () => void;
    getToursArchiveAsync: () => void;
    archiveTours: (id: string, archive: boolean) => void;
    liveTourTab: EnumTabsState;
}

const ArchivedTours: React.FC<IProps> = ({
    toursRef,
    archivedTours,
    deletedTours,
    scrollToTop,
    deleteArchived,
    showModal,
    toggleNotification,
    getToursAsync,
    getToursArchiveAsync,
    archiveTours,
    liveTourTab,
}) => {
    const { handleLogoutState } = useAuth();

    const restoreTours = async (id: string) => {
        scrollToTop();
        try {
            await getRestoreTour(id);
            if (liveTourTab === EnumTabsState.LiveTours) {
                getToursAsync();
            } else if (liveTourTab === EnumTabsState.ArchivedTours) {
                getToursArchiveAsync();
            }
            const message = linguiTranslateToString('Tour successfully restored');
            toggleNotification(EnumNotificationType.Success, message);
        } catch (error) {
            const message = linguiTranslateToString('There was an error restoring tour');
            toggleNotification(EnumNotificationType.Error, message);
            const err = error as Error;
            handleError(err, handleLogoutState);
        }
    };

    return (
        <div>
            <div className='archived-tours'>
                <b>{(archivedTours && archivedTours.length) || 0}</b> <Trans id='Archived tours' />
            </div>
            <TourRows
                deleteArchived={deleteArchived}
                tours={archivedTours}
                toursRef={toursRef}
                showModal={showModal}
                archiveTours={archiveTours}
                restoreTours={restoreTours}
                type={'archived'}
            />
            <div className='archived-tours'>
                <b>{(deletedTours && deletedTours.length) || 0}</b> <Trans id='Deleted tours' />
            </div>
            <TourRows
                tours={deletedTours}
                toursRef={toursRef}
                showModal={showModal}
                archiveTours={archiveTours}
                restoreTours={restoreTours}
                type={'deleted'}
            />
        </div>
    );
};
export default ArchivedTours;
