import React, { useEffect, useRef, useState } from 'react';
import { Trans } from '@lingui/react';
import { useConfig } from '../../utils/user-config-context/user-config-context';

import { usePasswordValidation } from '../../utils/custom-hooks/usePasswordValidation';
import backArrow from '../../../images/icons/modal-icons/back-arrow.svg';
import cross from '../../../images/icons/pwd-check-cross.svg';
import tick from '../../../images/icons/pwd-check-tick.svg';
import showPasswordIcon from '../../../images/icons/password-hidden.svg';
import hidePasswordIcon from '../../../images/icons/password-shown.svg';
import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
import selectArrow from '../../../images/dashboard-icons/select-arrow.svg';
import './create-edit-account.scss';
import ModalAccountManagement from '../../utils/modal/modal-account-management';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../../utils/notifications/notification';
import { EnumAccountStatus } from './account-management-canvas';
import { createAdminAccount, getMaster, updateAdminAccount } from '../../../api-helper/api-admin';
import { handleError } from '../../../api-helper/api-error-handling';
import { useAuth } from '../../../auth-routes/auth-context';
import NavSidebar from '../../utils/nav-sidebar/nav-sidebar';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';
import { Link } from 'react-router-dom';
import { IAccountMaster } from '../../../api-helper/interface/interfaces';
import { useHistory } from 'react-router';
import DropDownCountries from '../../utils/dropdown-front/drop-down-countries';
// import { DropDownCountries } from '@vva/front';

interface IFile {
  name: string;
  file: string;
  data?: any;
}

export interface IAdminAccount {
  id?: string;
  adminName?: string;
  status?: number;
  apiMaxUsers?: number;
  apiNoPayment?: number;
  accounts?: number;
  email?: string;
  countryId?: string;
  password?: string;
  countryName?: string;
  file?: IFile;
}

interface IPassword {
  newPassword: string;
  confirmNewPassword: string;
}

const CreateEditAccountMaster: React.FC = () => {
  const config = useConfig();
  const history = useHistory();
  const { handleLogoutState } = useAuth();
  const [showModalWindow, setShowModalWindow] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [countries, setCountries] = useState<any>();
  const [countryInfo, setCountryInfo] = useState<any>();
  const [showPasswordRequirements, setShowPasswordRequirements] = useState<boolean>(false);
  const [password, setPassword] = useState<IPassword>({
    newPassword: '',
    confirmNewPassword: '',
  });
  const [accountAdminData, setAccountAdminData] = useState<IAdminAccount>();
  const [ButtonDisableOnPasswordHandling, setButtonDisableOnPasswordHandling] = useState<boolean>(false);
  const [validLength, hasNumber, upperCase, specialChar, match] = usePasswordValidation({
    firstPassword: password.newPassword,
    secondPassword: password.confirmNewPassword,
    requiredLength: 8,
    numberValidation: true,
    lengthValidation: true,
    uppercaseValidation: true,
    specialCharacterValidation: true,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [accountToEdit, setAccountToEdit] = useState<IAccountMaster>();
  const ref = useRef<any>();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const partnerId = params.get('partnerId');
    if (partnerId) {
      getPartnerInfo(partnerId);
    }
  }, []);

  const getPartnerInfo = async (partnerId: string) => {
    try {
      const getPartner = await getMaster(partnerId);
      setAccountToEdit(getPartner);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  useEffect(() => {
    // Check if url has partner id
    if (accountToEdit && accountToEdit.partnerId) {
      setAccountAdminData(accountToEdit);
    }
  }, [accountToEdit]);

  useEffect(() => {
    if (accountToEdit && accountToEdit.partnerId) {
      if (countries && accountToEdit) {
        const userCountryId = accountToEdit.countryId || 'GB';
        const userCountryName = countries && countries.find((country: any) => country.country === userCountryId);
        setCountryInfo({
          ...countryInfo,
          countryName: userCountryName.name || '',
          countryId: userCountryName.country || '',
        });
      }
    } else {
      if (countries) {
        const userCountryId = (accountAdminData && accountAdminData.countryId) || 'GB';
        const userCountryName = countries && countries.find((country: any) => country.country === userCountryId);
        setCountryInfo({
          ...countryInfo,
          countryName: userCountryName.name || '',
          countryId: userCountryName.country || '',
        });
      }
    }
  }, [countries, accountToEdit]);

  useEffect(() => {
    if (config && config.countries && config.countryId) {
      setCountries(config.countries);
      setAccountAdminData({ ...accountAdminData, countryId: config.countryId, adminName: '', email: '', apiMaxUsers: 0 });
    }
    if (accountToEdit && accountToEdit.partnerId) {
      if (accountToEdit && countryInfo) {
        setAccountAdminData({ ...accountToEdit, ...countryInfo });
      }
    } else {
      if (countryInfo && countryInfo.countryName !== '') {
        setAccountAdminData({ ...accountAdminData, ...countryInfo });
      }
    }
  }, [config, accountToEdit, countryInfo]);

  useEffect(() => {
    setButtonDisableOnPasswordHandling(!checkPasswordParameters(validLength, hasNumber, upperCase, specialChar, match) ? true : false);
  }, [validLength, hasNumber, upperCase, specialChar, match]);

  const toggleNotification = (type: EnumNotificationType, message?: string) => {
    if (type === EnumNotificationType.Error) {
      setError(!error);
    }
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  const checkPasswordParameters = (
    validLength: boolean,
    hasNumber: boolean,
    upperCase: boolean,
    hasSpecialChar: boolean,
    match: boolean,
  ) => {
    const isValid = (currentValue: boolean) => currentValue === true;
    const params = [validLength, hasNumber, upperCase, hasSpecialChar, match];
    return params.every(isValid);
  };

  const showModal = (action: string) => {
    ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.DeleteAccount);
    setAction(action);
    setShowModalWindow(true);
  };

  const hideModal = () => {
    setShowModalWindow(false);
  };

  const buttonDisabled = () => {
    if (password.newPassword !== '' || password.confirmNewPassword !== '') {
      return ButtonDisableOnPasswordHandling;
    } else {
      return false;
    }
  };

  const handlerSelectItem = async (selectedValue: any) => {
    if (selectedValue && selectedValue.length) {
      setAccountAdminData({ ...accountAdminData, countryId: selectedValue[0].country, countryName: selectedValue[0].name });
    } else {
      setAccountAdminData({ ...accountAdminData, countryId: '', countryName: '' });
    }
  };

  const handlerChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
    setAccountAdminData({ ...accountAdminData, password: e.target.value });
  };

  const handlerInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountAdminData({ ...accountAdminData, [e.target.name]: e.target.value });
    if (error && accountAdminData?.email && accountAdminData.email !== '') {
      toggleNotification(EnumNotificationType.Info, linguiTranslateToString('No data to display for the selected time frame'));
    }
  };

  const handlerStatusAccount = async (accountStatus: string) => {
    let tempAccountUserData = { ...accountAdminData };
    let message;
    if (accountStatus === 'disable') {
      message = 'The user has been disabled';
      tempAccountUserData.status = 2;
    } else if (accountStatus === 'reactivate') {
      message = 'The user has been reactivate';
      tempAccountUserData.status = 1;
    } else if (accountStatus === 'delete') {
      message = 'The user has been delete';
      tempAccountUserData.status = -1;
    }
    setAccountAdminData(tempAccountUserData);
    await handlerUpdateAccount(tempAccountUserData);
    setShowModalWindow(false);
    toggleNotification(EnumNotificationType.Success, message);
  };

  const handlerUpdateAccount = async (accountAdmin?: IAdminAccount) => {
    const partnerData = accountAdmin ? accountAdmin : accountAdminData;
    try {
      await updateAdminAccount(accountToEdit?.partnerId || '', partnerData);
      toggleNotification(EnumNotificationType.Success, 'Admin was successfully updated');
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const handlerCreateNewAccount = async () => {
    ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.CreateAccount);
    try {
      await createAdminAccount(accountAdminData?.file?.file, accountAdminData);
      toggleNotification(EnumNotificationType.Success, 'New admin has been created');
      setTimeout(() => {
        history.push('admin-management');
      }, 1500);
    } catch (error: any) {
      const message = error.error.data.status.message || 'There was an error, please try again';
      toggleNotification(EnumNotificationType.Error, message);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const onClickDisable = () => {
    ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.DisableAccount);
    if (accountAdminData?.status && accountAdminData.status === 1) {
      showModal('disable');
    } else {
      showModal('reactivate');
    }
  };

  const onFileDrop = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files && e.target.files[0];
    if (file) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        const fileData: IFile = {
          data: fileReader.result,
          file: file,
          name: file.name,
        };
        setAccountAdminData({ ...accountAdminData, file: fileData });
      };
      fileReader.readAsDataURL(file);
    }
  };

  const pwdShouldContainSection = (
    <div className='cea-pwd-should-contain-section'>
      <div className='cea-pwd-should-contain-title'>
        <h3>
          <Trans id='Your password should be' />
        </h3>
      </div>
      <div className='cea-pwd-should-contain-items-container'>
        <div className='cea-pwd-should-contain-item'>
          <img className='cea-pwd-check-status' src={!validLength ? cross : tick} alt='Icon tick or cross' />
          <span className='cea-pwd-check-text'>
            <Trans id='At least 8 characters' />
          </span>
        </div>
        <div className='cea-pwd-should-contain-item'>
          <img className='cea-pwd-check-status' src={!upperCase ? cross : tick} alt='Icon tick or cross' />
          <span className='cea-pwd-check-text'>
            <Trans id='A capital letter' />
          </span>
        </div>
        <div className='cea-pwd-should-contain-item'>
          <img className='cea-pwd-check-status' src={!hasNumber ? cross : tick} alt='Icon tick or cross' />
          <span className='cea-pwd-check-text'>
            <Trans id='A number' />
          </span>
        </div>
        <div className='cea-pwd-should-contain-item'>
          <img className='cea-pwd-check-status' src={!specialChar ? cross : tick} alt='Icon tick or cross' />
          <span className='cea-pwd-check-text'>
            <Trans id='Contain a special character' /> <i>e.g. [!@£$%^&*()]</i>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <NavSidebar />
      {showNotification && (
        <Notification
          showNotification={showNotification}
          size={EnumNotificationSize.Large}
          type={typeNotification || EnumNotificationType.Info}
          message={notificationMessage}
          setShowNotification={setShowNotification}
        />
      )}
      <div className='dashboard-layout'>
        <div className='dashboard'>
          <div className='content-wrapper-account-management'>
            <div className='AM-data-container'>
              <div className='cea-content-wrapper'>
                {showModalWindow && (
                  <ModalAccountManagement
                    hideModal={hideModal}
                    modalAction={action}
                    statusAccount={handlerStatusAccount}
                    email={accountAdminData?.email || ''}
                  />
                )}
                <div className='prev-page-container'>
                  <Link
                    to={{
                      pathname: `/admin-management`,
                      state: {},
                    }}
                  >
                    <Trans
                      id='Back to previous page'
                      render={({ translation }) => (
                        <button className='prev-page-link'>
                          <img src={backArrow} alt='Go back arrow' />
                          {translation}
                        </button>
                      )}
                    />
                  </Link>
                </div>
                <div className='cea-general-container-master'>
                  <div className='ca-content-master'>
                    <div className='container-general'>
                      <div className='inputs-container-master'>
                        <div className='input-container-row'>
                          <div id='ca-master' className='field-container'>
                            <Trans
                              id='Master Name'
                              render={({ translation }) => (
                                <label className='create-tour-input-labels' htmlFor='Master'>
                                  <b>{translation}</b>
                                </label>
                              )}
                            />
                            <input
                              className={`general-field-input-text ${
                                accountAdminData?.adminName && accountAdminData?.adminName !== '' ? 'valid-input' : ''
                              }`}
                              type='text'
                              id='adminName'
                              name='adminName'
                              value={accountAdminData?.adminName || ''}
                              onChange={handlerInputValue}
                              disabled={accountAdminData?.status && accountAdminData.status === 2 ? true : false}
                            />
                          </div>

                          <div className='field-container'>
                            <Trans
                              id='Email'
                              render={({ translation }) => (
                                <label className='create-tour-input-labels' htmlFor='email'>
                                  <b>{translation}</b>
                                </label>
                              )}
                            />
                            <input
                              className={`general-field-input-text ${
                                accountAdminData?.status && accountAdminData.status === 2
                                  ? 'input-disable'
                                  : error
                                  ? 'input-wrong'
                                  : accountAdminData?.email && accountAdminData?.email !== ''
                                  ? 'valid-input'
                                  : ''
                              }`}
                              type='text'
                              id='email'
                              name='email'
                              value={accountAdminData?.email || ''}
                              onChange={handlerInputValue}
                              disabled={accountAdminData?.status && accountAdminData.status === 2 ? true : false}
                            />
                          </div>
                          <div id='ca-countries' className='field-container select-input'>
                            <Trans
                              id='Country'
                              render={({ translation }) => (
                                <label className='create-tour-input-labels'>
                                  <b>{translation}</b>
                                </label>
                              )}
                            />
                            <div className='cea-countries-container'>
                              {accountToEdit && accountToEdit.partnerId ? (
                                <DropDownCountries
                                  selectedProps={
                                    accountAdminData && accountAdminData.countryName && accountAdminData.countryName !== ''
                                      ? [accountAdminData.countryName]
                                      : ['']
                                  }
                                  dataset={countries ? countries : []}
                                  allowNew={false}
                                  isDisabled={accountAdminData?.status && accountAdminData.status === 2 ? true : false}
                                  labelKey={'name'}
                                  handleSelectedItem={handlerSelectItem}
                                  IsResultHighlightOnly={false}
                                  placeHolderValue=''
                                />
                              ) : (
                                <DropDownCountries
                                  selectedProps={
                                    accountAdminData && accountAdminData.countryName && accountAdminData.countryName !== ''
                                      ? [accountAdminData.countryName]
                                      : ['']
                                  }
                                  dataset={countries ? countries : []}
                                  allowNew={false}
                                  isDisabled={false}
                                  labelKey={'name'}
                                  handleSelectedItem={handlerSelectItem}
                                  IsResultHighlightOnly={false}
                                  placeHolderValue=''
                                />
                              )}
                              <img className='cea-arrow-country-dropdown' src={selectArrow} alt='Drop down menu arrow country' />
                            </div>
                          </div>
                        </div>
                        <div className='input-container-row'>
                          <div id='ca-password' className='field-container'>
                            <Trans
                              id='Password'
                              render={({ translation }) => (
                                <label className='create-tour-input-labels' htmlFor='newPassword'>
                                  <b>{translation}</b>
                                </label>
                              )}
                            />
                            <input
                              className={` general-field-input-text ${
                                accountAdminData?.status && accountAdminData.status === 2
                                  ? 'input-disable'
                                  : password.newPassword === ''
                                  ? ''
                                  : !match
                                  ? 'input-wrong'
                                  : 'valid-input'
                              }`}
                              type={showPassword ? 'text' : 'password'}
                              name='newPassword'
                              id='newPassword'
                              value={password.newPassword}
                              onChange={handlerChangePassword}
                              onFocus={() => setShowPasswordRequirements(true)}
                              onBlur={() => setShowPasswordRequirements(false)}
                              disabled={accountAdminData?.status && accountAdminData.status === 2 ? true : false}
                            />
                            <button
                              id='show-newPassword'
                              onClick={(e: React.MouseEvent): void => {
                                e.preventDefault();
                                setShowPassword(!showPassword);
                              }}
                              className='field-show-password-icon'
                            >
                              <img src={showPassword ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
                            </button>
                          </div>
                          <div className='field-container'>
                            <Trans
                              id='Confirm password'
                              render={({ translation }) => (
                                <label className='create-tour-input-labels' htmlFor='confirmNewPassword'>
                                  <b>{translation}</b>
                                </label>
                              )}
                            />
                            <input
                              className={` general-field-input-text ${
                                accountAdminData?.status && accountAdminData.status === 2
                                  ? 'input-disable'
                                  : password.confirmNewPassword === ''
                                  ? ''
                                  : !match
                                  ? 'input-wrong'
                                  : 'valid-input'
                              }`}
                              type={showConfirmPassword ? 'text' : 'password'}
                              name='confirmNewPassword'
                              id='confirmNewPassword'
                              value={password.confirmNewPassword}
                              onChange={handlerChangePassword}
                              onFocus={() => setShowPasswordRequirements(true)}
                              onBlur={() => setShowPasswordRequirements(false)}
                              disabled={accountAdminData?.status && accountAdminData.status === 2 ? true : false}
                            />
                            <button
                              id='show-confirmNewPassword'
                              onClick={(e: React.MouseEvent): void => {
                                e.preventDefault();
                                setShowConfirmPassword(!showConfirmPassword);
                              }}
                              className='field-show-password-icon'
                            >
                              <img src={showConfirmPassword ? hidePasswordIcon : showPasswordIcon} alt='Show password icon' />
                            </button>
                            {showPasswordRequirements && pwdShouldContainSection}
                          </div>
                          <div className='field-container'>
                            <Trans
                              id='Number of Pro accounts'
                              render={({ translation }) => (
                                <label className='create-tour-input-labels' htmlFor='Master'>
                                  <b>{translation}</b>
                                </label>
                              )}
                            />
                            <input
                              type='number'
                              className={`general-field-input-text ${(accountAdminData?.apiMaxUsers || 0) > 0 ? 'valid-input' : ''}`}
                              name='apiMaxUsers'
                              onChange={handlerInputValue}
                              value={accountAdminData?.apiMaxUsers !== undefined ? accountAdminData.apiMaxUsers : 0}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='input-container-row'>
                        <div className='container-options-admin'>
                          {accountToEdit && accountToEdit.partnerId ? (
                            <div>
                              {accountAdminData && accountAdminData.status === EnumAccountStatus.Active ? (
                                <div className='title-disable-reactivate'>
                                  <Trans id='Disable account' />
                                </div>
                              ) : (
                                <div className='title-disable-reactivate'>
                                  <Trans id='Reactivate account' />
                                </div>
                              )}

                              <div className='disable-delete-options-container-admin'>
                                <div className='disable-delete-btn-icon'>
                                  <button
                                    className={
                                      accountAdminData?.status && accountAdminData.status === 1
                                        ? 'btn-status-disabled'
                                        : 'btn-status-active'
                                    }
                                    onClick={() => {
                                      onClickDisable();
                                    }}
                                  ></button>
                                </div>
                                <div id='ea-disable' className='disable-delete-options'>
                                  {accountAdminData && accountAdminData.status === EnumAccountStatus.Active ? (
                                    <div className='disable-btn-container'>
                                      <div>
                                        <p className='ea-text'>
                                          <Trans id="The account will be deactivated and user won't be able to access the account anymore." />
                                        </p>
                                        <p className='ea-text'>
                                          <Trans id='You can reactivate the account anytime.' />
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className='disable-btn-container'>
                                      <div>
                                        <p className='ea-text'>
                                          <Trans id='The account will be reactivated and user will have access to the tours.' />
                                        </p>
                                        <p className='ea-text'>
                                          <Trans id='You can disable the account again anytime.' />
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='drag-and-drop-logo-container'>
                              <div className='container-text-logo'>
                                <div className='title-logo'>
                                  <Trans id='Upload their logo' />
                                </div>
                                <div className='subtext-logo'>
                                  <Trans id='Logo must be 1024 x 1024 px and in PNG format' />
                                </div>
                              </div>
                              <div className='container-image-input'>
                                <div className='drag-drop-text-container-circle'>
                                  <Trans
                                    id='Drop logo here'
                                    render={({ translation }) => (
                                      <p style={{ color: '#0e0333' }} className='drag-drop-text-title'>
                                        {translation}
                                      </p>
                                    )}
                                  />
                                  <Trans
                                    id='or'
                                    render={({ translation }) => (
                                      <div style={{ color: '#0e0333' }} className='media-drop-or-settings'>
                                        {translation}
                                      </div>
                                    )}
                                  />
                                  <Trans
                                    id='Upload logo'
                                    render={({ translation }) => <p className={'drag-drop-text-title-btn'}>{translation}</p>}
                                  />
                                </div>
                                <input
                                  id='input-file'
                                  type='file'
                                  name='logo-drop-zone'
                                  className='branding-input-settings-logo'
                                  onDragOver={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                  ref={ref}
                                  onDrop={(e: any) => onFileDrop(e)}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFileDrop(e)}
                                  onClick={() => {
                                    //Report Custom Category and Event
                                    ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.UploadLogoFile);
                                  }}
                                />
                                {accountAdminData?.file && (
                                  <img className='logo-image' src={accountAdminData.file.data} id='image-preview' alt='Uploaded' />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='container-options-admin'>
                          <div id='ca-master' className='field-container'>
                            <Trans
                              id='Bypass payment'
                              render={({ translation }) => (
                                <label className='create-tour-input-labels' htmlFor='Master'>
                                  <b>{translation}</b>
                                </label>
                              )}
                            />
                            <label className='switch-settings'>
                              <input
                                id='tripodRemoved'
                                type='checkbox'
                                checked={accountAdminData?.apiNoPayment === 1 ? true : false}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setAccountAdminData({
                                    ...accountAdminData,
                                    apiNoPayment: e.target.checked === true ? 1 : 0,
                                  })
                                }
                              />
                              <span id='slider-share-location' className='slider-settings round-settings'>
                                <span className={`slider-text-${false ? 'on' : 'off'}`}>{false ? 'ON' : 'OFF'}</span>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className='container-options-admin'></div>
                      </div>
                    </div>
                    {accountToEdit && accountToEdit.partnerId ? (
                      <div className='save-account-btn-container'>
                        <button
                          className={`${buttonDisabled() ? 'btn-save-invalid' : 'btn-save button-hover'}`}
                          onClick={() => handlerUpdateAccount()}
                          disabled={buttonDisabled() ? true : false}
                        >
                          <Trans id='Save Changes' />
                        </button>
                      </div>
                    ) : (
                      <div className='create-account-btn-container'>
                        <button
                          className={`${
                            !match || !accountAdminData?.file || accountAdminData?.adminName === '' || accountAdminData?.email === ''
                              ? 'btn-create-invalid'
                              : 'btn-create button-hover'
                          }`}
                          onClick={handlerCreateNewAccount}
                          disabled={buttonDisabled() ? true : false}
                        >
                          <Trans id='Create account' />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEditAccountMaster;
