import { getTripodCabURl, EnumHotspotsSize, EnumIcons, tripodCabs, EnumIconStyle } from '../enumerators/enumerators';

/************************  hotspot room photo ***********************/

// Hot spot creation function
export function customHotspotRoomFunc(hotSpotDiv: any, args: any): void {
	//Icon Configuration
	const icon = document.createElement('icon');

	// Asign icon style
	const iconStyle = args.iconStyle || EnumIconStyle.light;
	icon.classList.add(iconStyle);

	// Asign icon 
	const iconObj = args.iconType || EnumIcons.double_arrow;
	icon.classList.add(iconObj);
	icon.setAttribute('aria-hidden', 'true');


	// Define icon size
	const size = EnumHotspotsSize.medium;
	const iconSize = args.iconSize || size;
	const width = args.iconSize || size;
	const height = args.iconSize || size;

	// Asign icon color
	const color = args.iconColor || '#ffffff';
	icon.setAttribute('style', `color:${color}; font-size:${iconSize}`);

	hotSpotDiv.appendChild(icon);

	// Create room preview div
	const roomContainer = document.createElement('div');
	roomContainer.classList.add('room-container');
	roomContainer.setAttribute(
		'style',
		`position: absolute;  background-image:url(${args.src}); background-repeat: no-repeat; background-position: top; background-size: 180px 120px;`
	);

	roomContainer.setAttribute('alt', args.roomName);

	const span = document.createElement('span');
	span.innerHTML = args.roomName;

	roomContainer.appendChild(span);

	hotSpotDiv.appendChild(roomContainer);

	// Set hotspot style
	hotSpotDiv.setAttribute('style', `width:${width}; height:${height}; `);

	// Set icon margin
	if (iconSize === EnumHotspotsSize.small) {
		roomContainer.style.marginRight = '10px';
		roomContainer.style.marginBottom = '33px';
	} else if (iconSize === EnumHotspotsSize.medium) {
		roomContainer.style.marginRight = '19px';
		roomContainer.style.marginBottom = '38px';
	} else if (iconSize === EnumHotspotsSize.large) {
		roomContainer.style.marginRight = '39px';
		roomContainer.style.marginBottom = '60px';
	}
}

/************************  hotspot room photo ***********************/

/************************  hotspot media photo ***********************/

// Hot spot creation function
export function customHotspotMediaFunc(hotSpotDiv: any, args: any): void {
	//Icon Configuration
	const icon = document.createElement('icon');
	const span = document.createElement('span');
	const img = document.createElement('img');
	const paragraph = document.createElement('p');

	// Asign icon style
	const iconStyle = args.iconStyle || EnumIconStyle.light;
	icon.classList.add(iconStyle);

	// Asign icon 
	const iconObj = args.iconType || EnumIcons.camera;
	icon.classList.add(iconObj);
	icon.setAttribute('aria-hidden', 'true');

	// Define icon size
	const size = EnumHotspotsSize.small;
	const iconSize = args.iconSize || size;
	const width = args.iconSize || size;
	const height = args.iconSize || size;

	// Asign icon color
	const color = args.iconColor || '#ffffff';
	icon.setAttribute('style', `color:${color}; font-size:${iconSize}`);

	hotSpotDiv.appendChild(icon);

	// Set hotspot style	
	hotSpotDiv.setAttribute('style', `width:${width}; height:${height}; background:#0E0333; padding: 16px;`);
	hotSpotDiv.setAttribute('height', '120px');

	// Set image text
	paragraph.innerHTML = args.text || '';

	paragraph.classList.add('custom-hotspot-media-paragraph');

	// Set Image src
	img.setAttribute('src', args.src);

	span.appendChild(img);

	// Check title
	if (args.title) {
		const title = document.createElement('p');

		title.innerHTML = args.title || '';

		const titleColor = args.titleColor || '#2c2e4a';

		title.classList.add('custom-hotspot-media-title');

		title.setAttribute('style', `color:${titleColor};`);

		span.appendChild(title);

		args.title
			? paragraph.setAttribute('style', `margin-top: 40px`)
			: paragraph.setAttribute('style', `margin-top: 10px`);
	}


	span.appendChild(paragraph);

	hotSpotDiv.appendChild(span);

	// Set modal width
	span.style.width = span.scrollWidth - 20 + 'px';
	span.style.bottom = '55px';
	span.style.marginBottom = '10px';
}

/************************  hotspot media photo ***********************/

/************************ custom hotspot icon ***********************/

// Hot spot creation function
export function customHotspotIconFunc(hotSpotDiv: any, args: any): void {
	//Icon Configuration
	const icon = document.createElement('icon');

	// Asign icon style
	const iconStyle = args.iconStyle || EnumIconStyle.light;
	icon.classList.add(iconStyle);

	// Asign icon 
	const iconObj = args.iconType || EnumIcons.double_arrow;
	icon.classList.add(iconObj);
	icon.setAttribute('aria-hidden', 'true');

	// Asign icon color
	const color = args.iconColor || '#ffffff';

	// Asign icon border color
	const borderColor = args.borderColor || '#0E0333';

	// Define icon size
	const size = EnumHotspotsSize.small;
	const iconSize = args.iconSize || size;
	const width = args.iconSize || size;
	const height = args.iconSize || size;

	// Append Icon
	icon.setAttribute('style', `color:${color}; font-size:${iconSize}`);
	hotSpotDiv.appendChild(icon);

	hotSpotDiv.setAttribute('style', `width:${width}; height:${height}; background:${borderColor}; padding: 16px;`);
}

/************************ custom hotspot icon ***********************/

// Hot spot creation function
export function customTripodCoverFunc(hotSpotDiv: any, args: any): void {
	// Define background color
	const backgroundColor = args.backgroundColor || '#ffffff';

	//Tripod cap uRL
	const productCap = args.productName && getTripodCabURl(args.productName) ? getTripodCabURl(args.productName) : null;

	// Set tripod cap URL
	const backgroundImage = args.src || productCap || tripodCabs.default;

	// Set hotspot attribute
	hotSpotDiv.setAttribute('style', `background-image:url(${backgroundImage}); background-color:${backgroundColor}`);
}

/************************ custom hotspot icon ***********************/

/************************  hotspot text  ***********************/

// Hot spot creation function
export function customHotspotTextFunc(hotSpotDiv: any, args: any): void {
	//Icon Configuration
	const icon = document.createElement('icon');
	const span = document.createElement('span');

	// Asign icon style
	const iconStyle = args.iconStyle || EnumIconStyle.light;
	icon.classList.add(iconStyle);

	// Asign icon 
	const iconObj = args.iconType || EnumIcons.notavailable;
	icon.classList.add(iconObj);
	icon.setAttribute('aria-hidden', 'true');

	// Define icon size
	const size = EnumHotspotsSize.small;
	const iconSize = args.iconSize || size;
	const width = args.iconSize || size;
	const height = args.iconSize || size;

	// Asign icon color
	const color = args.iconColor || '#ffffff';
	icon.setAttribute('style', `color:${color}; font-size:${iconSize}`);

	hotSpotDiv.appendChild(icon);

	// Set hotspot style
	hotSpotDiv.setAttribute('style', `width:${width}; height:${height}; background:#0E0333; padding: 16px;`);

	// Check title
	if (args.title) {
		const title = document.createElement('p');

		title.innerHTML = args.title || '';

		const titleColor = args.titleColor || '#2c2e4a';

		title.classList.add('custom-hotspot-text-title');

		title.setAttribute('style', `color:${titleColor};`);

		span.appendChild(title);
	}

	// Create paragraph
	const paragraph = document.createElement('p');

	paragraph.classList.add('custom-hotspot-text-paragraph');

	// Assign paragraph color
	const paragraphColor = args.paragraphColor || '#000000';

	args.title
		? paragraph.setAttribute('style', `color:${paragraphColor}; margin-top: 40px`)
		: paragraph.setAttribute('style', `color:${paragraphColor}; margin-top: 10px`);

	paragraph.innerHTML = (args.text || '').replace(/\n/g, '<br/>');

	// Check URL
	if (args.url) {
		const link = document.createElement('a');
		link.classList.add('custom-hotspot-text-url');
		link.setAttribute('target', '_blank');
		link.setAttribute('rel', 'noreferrer');
		link.setAttribute('href', args.url);
		link.innerHTML = args.url;
		paragraph.appendChild(link);
	}

	span.appendChild(paragraph);

	hotSpotDiv.appendChild(span);
}

/************************  hotspot text  ***********************/

/************************  hotspot video  ***********************/

export function customHotspotVideoFunc(hotSpotDiv: any, args: any): void {
	//Icon Configuration
	const icon = document.createElement('icon');
	const span = document.createElement('span');

	const video = document.createElement('video');

	const paragraph = document.createElement('p');

	// Asign icon style
	const iconStyle = args.iconStyle || EnumIconStyle.light;
	icon.classList.add(iconStyle);

	// Asign icon 
	const iconObj = args.iconType || EnumIcons.cinema;
	icon.classList.add(iconObj);
	icon.setAttribute('aria-hidden', 'true');

	// Define icon size
	const size = EnumHotspotsSize.small;
	const iconSize = args.iconSize || size;
	const width = args.iconSize || size;
	const height = args.iconSize || size;

	// Asign icon color
	const color = args.iconColor || '#ffffff';
	icon.setAttribute('style', `color:${color}; font-size:${iconSize}`);

	hotSpotDiv.appendChild(icon);

	// Set hotspot style	
	hotSpotDiv.setAttribute('style', `width:${width}; height:${height}; background:#0E0333; padding: 16px;`);

	// Set video text
	paragraph.innerHTML = args.text || '';

	paragraph.classList.add('custom-hotspot-video-paragraph');

	// Set Image src	
	video.setAttribute('src', args.src);

	// Enable autoplay
	if (args.enableAutoplay) {
		video.autoplay = true;
	}

	// Enable video controllers
	if (args.enableControls) {
		video.controls = true;
	}

	// Enable video sound
	if (args.isMuted) {
		video.muted = true;
	}

	// On mouse enter play video
	span.addEventListener('mouseenter', function () {
		video.play();
	});

	// On mouse out pause video
	span.addEventListener('mouseleave', function () {
		video.pause();
	});

	span.appendChild(video);

	// Check title
	if (args.title) {
		const title = document.createElement('p');

		title.innerHTML = args.title || '';

		const titleColor = args.titleColor || '#2c2e4a';

		title.classList.add('custom-hotspot-media-title');

		title.setAttribute('style', `color:${titleColor};`);

		span.appendChild(title);

		args.title
			? paragraph.setAttribute('style', `margin-top: 40px`)
			: paragraph.setAttribute('style', `margin-top: 10px`);
	}


	span.appendChild(paragraph);

	hotSpotDiv.appendChild(span);

	// Set modal width	
	span.style.width = span.scrollWidth - 20 + 'px';
	span.style.bottom = '55px';
	span.style.marginBottom = '10px';
}

/************************  hotspot video  ***********************/
