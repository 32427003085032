import React, { useEffect, useState } from 'react';
import SearchBar from '../../utils/searchbar/searchbar';
import { AccountNotFoundCard } from './not-found-account-management';
import { Trans } from '@lingui/react';
import DataTable from '../../utils/data-table/data-table';
import { defineMessage } from '@lingui/macro';
import CustomSelect from '../../utils/custom-select/custom-select';
import { IChangeSortPagination, IUserAccount } from './account-management-canvas';
import { IAccount } from '../../../api-helper/interface/interfaces';
import LoadingSpinner from '../../utils/loading-spinner/loading-spinner';
import arrow from '../../../images/dashboard-icons/select-arrow.svg';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import { IModules } from '../dashboard/dashboard';
import { useHistory } from 'react-router';
import FilterableDropdownList from '../../utils/dropdown-front/filterable-drop-down-list';
// import { FilterableDropdownList } from '@vva/front';

interface IProps {
    totalNumAccounts: number;
    keyword: string;
    handlerSetSearchBarValue: (keywordValue: string) => void;
    handlerSearching: (isSearchbarLoaded: boolean, page: number) => void;
    loadedView: boolean;
    goToEditCreateAccount: (account?: IUserAccount) => void;
    totalCount: number;
    orderOptionName: string;
    showCustomDropDown: boolean;
    toggleCustomDropdown: (option?: boolean) => void;
    handleConnectUser: (account: IUserAccount) => void;
    changeUserSortByPagination: (option: IChangeSortPagination) => void;
    userPage: number;
    userPageCount: number;
    goToEditUsers: (account?: IUserAccount) => void;
    handleUserPaginationSearch: (pageSelected: any) => void;
    usersData?: IAccount[];
    setUserFilterByMasterName: (name: string) => void;
    userLoadedPage: boolean;
    adminsNames: any;
    userFilterByMasterName?: string;
    setUserPage: (state: number) => void;
    setUserOrderOptionName: (state: string) => void;
}

const UsersAccountManagement: React.FC<IProps> = ({
    totalNumAccounts,
    keyword,
    handlerSetSearchBarValue,
    handlerSearching,
    loadedView,
    goToEditCreateAccount,
    totalCount,
    orderOptionName,
    showCustomDropDown,
    toggleCustomDropdown,
    handleConnectUser,
    changeUserSortByPagination,
    userPage,
    usersData,
    setUserFilterByMasterName,
    userLoadedPage,
    userFilterByMasterName,
    adminsNames,
    goToEditUsers,
}) => {
    const history = useHistory();
    const [itHasUsers, setItHasUsers] = useState<boolean>(false);
    const [showMasterTab, setShowMasterTab] = useState<boolean>(false);
    const [labels, setLabels] = useState([
        { tableTitle: <Trans id='Name' />, value: 'fullName' },
        { tableTitle: <Trans id='Email' />, value: 'email' },
        { tableTitle: <Trans id='Live Tours' />, value: 'mostLiveTours' },
        { tableTitle: <Trans id='Type' />, value: 'proPlan' },
        { tableTitle: <Trans id='Status' />, value: 'status' },
        { tableTitle: <Trans id='Expiration' />, value: 'proExpiration' },
        { tableTitle: <Trans id='Created' />, value: 'createdAt' },
        { tableTitle: <Trans id='Last Login' />, value: 'lastLogin' },
        { tableTitle: <Trans id='Actions' />, value: '' },
    ]);

    const config = useConfig();
    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (config) {
            const mastersTab = config.modules.find((a: IModules) => Object.keys(a)[0] === 'masterTab')?.masterTab;
            setShowMasterTab(mastersTab);
        }
    }, [config]);

    useEffect(() => {
        if (showMasterTab) {
            const temp = [...labels];
            temp.unshift({ tableTitle: <Trans id='Master' />, value: 'partnerName' });
            setLabels(temp);
        }
    }, [showMasterTab]);

    useEffect(() => {
        if (usersData && usersData.length) {
            setItHasUsers(true);
        } else {
            setItHasUsers(false);
        }
    }, [usersData]);

    useEffect(() => {
        managePaginationSortBy();
    }, []);

    const managePaginationSortBy = () => {
        const pageFromUrl = Number(params.get('page'));
        const sortByUrl = params.get('sortBy');
        const obj: IChangeSortPagination = {};

        if (sortByUrl) {
            obj.sortBy = sortByUrl;
        }
        if (pageFromUrl) {
            obj.page = pageFromUrl - 1 || 0;
        }
        changeUserSortByPagination(obj);
    };

    const changeSortBy = (sortBy: string) => {
        const pageFromUrl = Number(params.get('page'));
        if (pageFromUrl) {
            history.push(`/users-management?sortBy=${sortBy}&page=${userPage + 1}`);
        } else {
            history.push(`/users-management?sortBy=${sortBy}`);
        }

        changeUserSortByPagination({ sortBy: sortBy });
    };

    const handleSelectedPartnerItem = async (selectedValue: any) => {
        if (selectedValue.length) {
            setUserFilterByMasterName(selectedValue[0].name);
        }
    };

    const selectAdminsFilter = (e: any) => {
        setUserFilterByMasterName(e.target.value);
    };

    return (
        <>
            <div className={`${itHasUsers ? 'am-header' : !itHasUsers && totalNumAccounts !== 0 ? 'am-header' : 'am-header-no-content'}`}>
                {itHasUsers ? (
                    <Trans
                        id={'Search for an account'}
                        render={({ translation }) => (
                            <SearchBar
                                placeholder={String(translation)}
                                searchBarValue={keyword}
                                handlerSetSearchBarValue={handlerSetSearchBarValue}
                                handlerSearching={handlerSearching}
                            />
                        )}
                    />
                ) : !itHasUsers && totalNumAccounts !== 0 ? (
                    <Trans
                        id={'Search for an account'}
                        render={({ translation }) => (
                            <SearchBar
                                placeholder={String(translation)}
                                searchBarValue={keyword}
                                handlerSetSearchBarValue={handlerSetSearchBarValue}
                                handlerSearching={handlerSearching}
                            />
                        )}
                    />
                ) : null}
                {showMasterTab && (
                    <div className='filter-admin'>
                        <Trans
                            id={'Filter by Master name'}
                            render={({ translation }: any) => (
                                <FilterableDropdownList
                                    selectedProps={userFilterByMasterName ? [userFilterByMasterName] : ['']}
                                    dataset={adminsNames ? adminsNames : []}
                                    allowNew={false}
                                    isDisabled={false}
                                    labelKey={'name'}
                                    handleSelectedItem={handleSelectedPartnerItem}
                                    IsResultHighlightOnly={false}
                                    selectNewOnBlur={selectAdminsFilter}
                                    placeHolderValue={translation}
                                />
                            )}
                        />
                        <img className='arrow-filter' alt='arrow' src={arrow} />
                    </div>
                )}
            </div>
            {userLoadedPage && (
                <AccountNotFoundCard
                    itHasData={itHasUsers}
                    loadedView={loadedView}
                    totalNumAccounts={totalNumAccounts}
                    goToEditCreateAccount={goToEditCreateAccount}
                />
            )}
            {itHasUsers && (
                <>
                    <div className='sort-by-num-accounts-container'>
                        <Trans
                            id='Users'
                            render={({ translation }) => (
                                <p>
                                    <b>{totalCount || 0}</b> {translation}
                                </p>
                            )}
                        />
                        <div className='am-sort-by-container'>
                            <label className='am-sort-by-label'>
                                <Trans id='Sort by' />
                            </label>
                            <CustomSelect
                                value={orderOptionName}
                                options={[
                                    defineMessage({ message: 'Alphabetical order' }),
                                    defineMessage({ message: 'Newest - Oldest' }),
                                    defineMessage({ message: 'Oldest - Newest' }),
                                    defineMessage({ message: 'Last login' }),
                                    defineMessage({ message: 'Most Live Tours' }),
                                ]}
                                changeDropdownValue={changeSortBy}
                                showCustomDropDown={showCustomDropDown}
                                setShowCustomDropDown={toggleCustomDropdown}
                                customClass={`am-custom-select ${showCustomDropDown ? 'am-custom-select-focus' : ''}`}
                                customArrow='am-custom-arrow'
                            />
                        </div>
                    </div>
                    <div className='am-data-table'>
                        {userLoadedPage ? (
                            <>
                                <DataTable
                                    accounts={usersData}
                                    labels={labels}
                                    firstAction={goToEditUsers}
                                    secondAction={handleConnectUser}
                                    isLoaded={true}
                                />
                            </>
                        ) : (
                            <LoadingSpinner loaded={false} />
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default UsersAccountManagement;
