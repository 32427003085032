import React, { useEffect, useState } from 'react';
import noFloorPlan from '../../../../images/empty-states-illustrations/no-floor-plan.svg';
import { Trans } from '@lingui/react';
import { handleError } from '../../../../api-helper/api-error-handling';
import { useAuth } from '../../../../auth-routes/auth-context';
import { downloadAllAdminFloorplans, getFloorplans } from '../../../../api-helper/api-floorplan';
import { EnumFloorplanOrigin, IFloorplans } from '../../../../api-helper/interface/interfaces';
import { IModalContent } from '../../../utils/tour-views/interface';
import LoadingSpinner from '../../../utils/loading-spinner/loading-spinner';
import UploadFloorplanModal from '../../../utils/modal/upload-floorplan-modal';
import { EnumNotificationType } from '../../../utils/notifications/notification';
import { EnumUploadState, IFileArray } from '../../../editing-viewer/interaces';

interface IProps {
  panoramas: any;
  setApiCallLoaded: (state: boolean) => void;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
  currentTour: any;
  setCurrentTour: (state: any) => void;
}

export interface ISelectedFloorPlan {
  photoUrl: string;
  floorplanId: string;
}

export interface ISelectedHotspot {
  photoUrl: string;
  hotspotId: string;
}

export interface IFloorplanHotspots {
  id?: string;
  x: number;
  y: number;
  destId?: string;
  photoUrl?: string;
  name?: string;
  pending?: boolean;
}

const Floorplan: React.FC<IProps> = ({ panoramas, setApiCallLoaded, toggleNotification, currentTour, setCurrentTour }) => {
  const [floorplanData, setFloorplanData] = useState<IFloorplans[]>();
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<IModalContent>({ id: '' });
  const [loaded, setLoaded] = useState<boolean>(false);
  const { handleLogoutState } = useAuth();
  const [selectedFloorplan, setSelectedFloorplan] = useState<ISelectedFloorPlan>();
  const [hotspots, setHotspots] = useState<IFloorplanHotspots[]>();
  const [expandFloorplan, setExpandFloorplan] = useState<boolean>(false);
  const [hoverImage, setHoverImage] = useState<ISelectedHotspot>();
  const [hoverImageExpanded, setHoverImageExpanded] = useState<ISelectedHotspot>();
  const [filesArray, setFilesArray] = useState<IFileArray[]>([]);
  const [currentTourId, setCurrentTourId] = useState<string>();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tourIdFromParams = String(params.get('tourId'));
    setCurrentTourId(tourIdFromParams);
  }, []);

  useEffect(() => {
    if (currentTourId) {
      getFloorplansAsync();
    }
  }, [currentTourId]);

  const toggleModalHide = (): void => {
    setDisplayModal(false);
  };

  const changeSelectedFlooplan = (floorplanId: string, photoUrl: string) => {
    const findHotspots = floorplanData?.find(floorplan => floorplan.floorplanId === floorplanId);
    setHotspots(findHotspots?.hotspots);
    setSelectedFloorplan({
      photoUrl: photoUrl,
      floorplanId: floorplanId,
    });
  };

  const getFloorplansAsync = async () => {
    try {
      const floorplans = await getFloorplans(currentTourId || '');
      if (floorplans?.length) {
        let formatHotspots;
        if (!selectedFloorplan || selectedFloorplan?.photoUrl === '') {
          changeSelectedFlooplan(floorplans[0].floorplanId, floorplans[0].photoUrl);
          formatHotspots = floorplans[0]?.hotspots.map(hotspot => {
            return {
              id: hotspot.id,
              x: hotspot.x,
              y: hotspot.y,
              destId: hotspot.panoramaId,
              photoUrl: hotspot.photoUrl,
            };
          });
        } else {
          const floorplanHotspotIndex = floorplans.findIndex(floorplan => floorplan.floorplanId === selectedFloorplan.floorplanId);
          formatHotspots = floorplans[floorplanHotspotIndex]?.hotspots.map(hotspot => {
            return {
              id: hotspot.id,
              x: hotspot.x,
              y: hotspot.y,
              destId: hotspot.panoramaId,
              photoUrl: hotspot.photoUrl,
            };
          });
        }

        const floorplanFiles = floorplans.map(floorplan => {
          return {
            id: floorplan.floorplanId,
            data: floorplan.photoUrl,
            completion: EnumUploadState.Completed,
            origin: floorplan.origin,
          };
        });

        setHotspots(formatHotspots);
        setFilesArray(floorplanFiles);
        setFloorplanData(floorplans);
      } else {
        changeSelectedFlooplan('', '');
        setFilesArray([]);
        setFloorplanData([]);
      }
      setLoaded(true);
    } catch (error) {
      setApiCallLoaded(true);
      const err = error as Error;
      handleError(err, handleLogoutState);
      setLoaded(true);
    }
  };

  const toggleModalShow = (modalContent: IModalContent) => {
    setModalContent(modalContent);
    setDisplayModal(true);
  };

  const downloadFloorplans = async () => {
    if (currentTourId) {
      try {
        toggleNotification(EnumNotificationType.Info, 'Your floor plan is downloading and should be ready in a few seconds.');
        const download = await downloadAllAdminFloorplans(currentTourId);
        // Get file name
        const filename = currentTour.readCode + '.zip';
        const url = window.URL.createObjectURL(new Blob([download.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        toggleNotification(EnumNotificationType.Error, 'There was an error.');
      }
    }
  };

  const checkFloorplanOrigin = (): boolean => {
    const findAdminFloorplans = floorplanData?.find(f => f.origin === EnumFloorplanOrigin.admin);
    if (findAdminFloorplans) {
      return true;
    } else {
      return false;
    }
  };

  if (checkFloorplanOrigin()) {
    return (
      <div className='container-floorplan-images' style={{ height: '50vh' }}>
        <div className='floorplan-container' style={{ display: 'flex' }}>
          <div className='display-flex flex-center' style={{ width: '100%', marginBottom: '20px' }}>
            <button className='button-no-floor button-hover' onClick={downloadFloorplans}>
              <Trans id='Download floorplan' />
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {expandFloorplan && (
          <div className='expanded-floorplan-container'>
            <div className='expanded-floorplan'>
              <button className='minimise-button' style={{ right: '10%' }} onClick={() => setExpandFloorplan(false)} />
              <img src={selectedFloorplan?.photoUrl} className='selected-floorplan-pop-up' alt='main-floorplan' />
              {hotspots?.map((hotspot, i) => (
                <div
                  key={i}
                  style={{ left: `${hotspot.x}%`, top: `${hotspot.y}%` }}
                  onPointerOver={() => setHoverImageExpanded({ photoUrl: hotspot.photoUrl || '', hotspotId: hotspot.id || '' })}
                  onPointerLeave={() => setHoverImageExpanded({ photoUrl: '', hotspotId: '' })}
                  className='floorplan-hotspot'
                >
                  {hoverImageExpanded?.photoUrl !== '' && hoverImageExpanded?.hotspotId === hotspot.id && (
                    <div style={{ position: 'relative' }}>
                      <div className='hotspot-hover-container'>
                        <img style={{ width: '100%', borderRadius: '8px 8px 0 0' }} src={hotspot.photoUrl} alt='hover' />
                        {hotspot.name}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <LoadingSpinner loaded={loaded} />
        {displayModal && (
          <UploadFloorplanModal
            setCurrentTour={setCurrentTour}
            currentTour={currentTour}
            hotspots={hotspots}
            setHotspots={setHotspots}
            selectedFloorplan={selectedFloorplan}
            changeSelectedFlooplan={changeSelectedFlooplan}
            panoramas={panoramas}
            floorplanData={floorplanData}
            hideModal={toggleModalHide}
            modalContent={modalContent}
            loadFloorplan={getFloorplansAsync}
            toggleNotification={toggleNotification}
            getFloorplansAsync={getFloorplansAsync}
            filesArray={filesArray}
            setFilesArray={setFilesArray}
          />
        )}
        {floorplanData?.length && floorplanData[0].photoUrl ? (
          <div className='container-floorplan-images'>
            <div className='floorplan-container'>
              <div className='floorplan-images'>
                <div style={{ flex: '1 1', display: 'flex' }}>
                  <div className='floorplan-list-left'>
                    {floorplanData.map((floorplan, i) => (
                      <button
                        key={i}
                        className='floorplan-option-container'
                        onClick={() => changeSelectedFlooplan(floorplan.floorplanId, floorplan.photoUrl)}
                      >
                        <img src={floorplan.photoUrl} className='floorplan-option' alt='floorplan' />
                      </button>
                    ))}
                  </div>
                  <div className='floorplan-selected-container' style={{ height: '500px' }}>
                    {hotspots?.map((hotspot, i) => (
                      <div
                        key={i}
                        style={{ left: `${hotspot.x}%`, top: `${hotspot.y}%` }}
                        onPointerOver={() => setHoverImage({ photoUrl: hotspot.photoUrl || '', hotspotId: hotspot.id || '' })}
                        onPointerLeave={() => setHoverImage({ photoUrl: '', hotspotId: '' })}
                        className='floorplan-hotspot'
                      >
                        {hoverImage?.photoUrl !== '' && hoverImage?.hotspotId === hotspot.id && (
                          <div style={{ position: 'relative' }}>
                            <div className='hotspot-hover-container'>
                              <img style={{ width: '100%', borderRadius: '8px 8px 0 0' }} src={hotspot.photoUrl} alt='hover' />
                              {hotspot.name}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    <button className='expand-button' onClick={() => setExpandFloorplan(true)} />
                    <img src={selectedFloorplan?.photoUrl} className='selected-floorplan-pop-up' alt='main-floorplan' />
                  </div>
                </div>
                <div style={{ flex: '1 1', padding: '0 20px' }}>
                  <Trans
                    id={'Connected rooms:'}
                    render={({ translation }: any) => <div style={{ fontWeight: 'bold', marginBottom: '16px' }}>{translation}</div>}
                  />
                  {hotspots?.length ? (
                    <div className='container-hotspot-images-floorplan'>
                      {hotspots?.map(hotspot => (
                        <button
                          id='edit-panoramas'
                          key={hotspot.id}
                          style={{ position: 'relative', margin: '0px 12px 8px 12px' }}
                          className='btn-remove-default'
                        >
                          <img className='image-for-download' style={{ width: '162px' }} src={hotspot.photoUrl} alt='panoramas' />
                          <div className='img-label'>{hotspot.name}</div>
                        </button>
                      ))}
                    </div>
                  ) : (
                    <Trans
                      id={'This floorplan doesn’t have any room connected. Click on Edit floorplan button to link your images.'}
                      render={({ translation }: any) => (
                        <div style={{ fontWeight: '400', color: '#757575', width: '50%' }}>{translation}</div>
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='display-flex flex-center' style={{ width: '100%', marginBottom: '20px' }}>
              <button
                className='button-no-floor button-hover'
                onClick={() => {
                  const modalContent = {
                    id: 'floor-plan',
                    title: '',
                    tourId: currentTourId,
                    editFloorplan: true,
                  };
                  toggleModalShow(modalContent);
                }}
              >
                <Trans id='Edit floorplan' />
              </button>
            </div>
          </div>
        ) : (
          <div className='container-floorplan-images'>
            <div className='container-floor-plan'>
              <div className='container-text-no-floor'>
                <div className='title-text-no-floor'>
                  <Trans id='You haven’t uploaded any floorplan yet!' />
                </div>
                <div className='sub-text-no-floor'>
                  <Trans
                    id='Click on the <0>Upload floorplan</0> button to upload it and link your images.'
                    components={{ 0: <strong className='text-strong'></strong> }}
                  />
                </div>
                <button
                  className='button-no-floor-empty button-hover'
                  onClick={() => {
                    const modalContent = {
                      id: 'floor-plan',
                      title: '',
                      tourId: currentTourId,
                      editFloorplan: false,
                    };
                    toggleModalShow(modalContent);
                  }}
                >
                  <Trans id='Upload floorplan' />
                </button>
              </div>
              <img src={noFloorPlan} className='no-floor-plan' alt='illustration' />
            </div>
          </div>
        )}
      </>
    );
  }
};

export default Floorplan;
